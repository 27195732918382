import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Card, CardBody, CardImg, UncontrolledCollapse, CustomInput, Table, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2'
import config from '../../../config';
// import PricingTable from '../../pricing/PricingTable'; 
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
 
const EasyecomDetails = (props) => {
	const [loader, setLoader] = useState(false) ;
	//const [easyuser, seteasyuser] = useState() ; 
	
	const [hidefields, setHideFields] = useState(false) ; 
 
    const { register:addMerchantMarketplace, handleSubmit:MerchantMarketplaceSubmit,watch:watchAddpswd, formState: { errors } } = useForm();
	
	const [easyUser, set_easyecomuser] = useState(false) ; 
	const [easyMp, set_easyemp] = useState(false) ; 
	 
	const onAddMerchantMarketplaceSubmit = (data) => {
  
    let formData = new FormData();
	 
	
	};
	
	
   //  useEffect(() => {}, [])
	const onError = (errors, e) => console.log('Error', errors);   
	let esuser = '' ; 
	axios({
	method: "post",
	url : process.env.REACT_APP_MERCHANT_EASYECOM_STATUS,
	data: {"merchant_id" : props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ? props.user[0].id:''}
	}).then(function (response) {
		if(response.data.error === false){
			var curdat = response.data ;
			if(curdat.data !=undefined && curdat.data > 0 ) {
			 set_easyecomuser(curdat.data) ;
			// console.log('current user is '+ JSON.stringify(curdat.data)) ;
			esuser = curdat.data ;
			set_easyemp(curdat.msg) ;
			//alert('user data '+esuser) ;
			}
			 
		}else {
			esuser = false ;
		}
	}).catch((err) => {
		console.log(' got error '+err) ;
		esuser = false ;
	});
	
    return (
      <>
      {loader && <div className="formLoader"><Spinner /></div>}
         
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">EasyEcom</CardTitle>
            </div>
            <Row>
				<div> 
				<br/><b> Easy Ecom account is Enabled.</b> <br/> 
				
				Click the below button to complete setup of Amazon API.
				
				<br/>
				
				
				{ easyUser > 0 &&
				 
				<a href={'https://app.easyecom.io/Amazon/RefreshIntegration?credential_id='+easyMp+'&success_url=https://dashboard.tekipost.com/profile'}> Click to verify Amazon Marketplace Integration </a>

				}
				
				<br/>
					
					
				<br/>
				
				</div>
			 
           </Row>
        </>
    );
}

export default EasyecomDetails ;