import React, {useState, useEffect} from "react";
import {Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col} from 'reactstrap';
import classnames from 'classnames';
import ShipmentAll from "./ShipmentAll";
import PickupPending from "../orders/PickupPending";
import ActionTaken  from  "../orders/ActionTaken" ;
import { checkPermission } from "../../ObjectExist";

const Shipments = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
//   const toggleDataAdd = () => setDataAdd(!dataAdd);
  useEffect(() => {
}, []);

    return(
        <div className="content-body position-relative overflow-hidden">
            <div>
					<div className="page-header main-header mb-3"> 
					<h1>Manage Shipments</h1>
					
					<ul className="master-tabs nav nav-tabs">
					<li className="text-white nav-item"><a href="/allshipments#section_all_shipments" className="active nav-link"><span className="text-white">All Shipments</span></a></li>
					<li className="text-white nav-item"><a href="/allshipments#section_pending_shipments" className="nav-link"><span className="text-white">Pickup Pending</span></a></li>
					</ul>					
						 
                </div>
				  <div className="master-content card border-0 shadow mb-6">
				      
					  <div id="section_all_shipments">
                         <Row>
                                <Col sm="12">
                                    {(checkPermission(props.user, props?.user[0]?.permissions?.manage_Shipments?.all_shipments) || props.user[0].user_type === "Merchant") &&
                                        <ShipmentAll user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </div>
					 	 <div id="section_pending_shipments">
                            <Row>
                                <Col sm="12" id="section_pending_shipments">
                                    {(checkPermission(props.user, props?.user[0]?.permissions?.manage_Shipments?.pickup_pending) || props.user[0].user_type === "Merchant") &&
                                        <PickupPending user={props.user} />
                                    }
                                </Col>
                            </Row> 
						</div>	
 	               
						 				
				</div>  	
            </div>
        </div>
    )
}
export default Shipments