import React, {useState, useEffect} from "react";
import {Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col} from 'reactstrap';
import classnames from 'classnames';
import LiveNdr from "./LiveNdr";
import AllNdr from "./AllNdr";
import ActionTaken  from  "./ActionTaken" ;

const Ndr = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      }
//   const toggleDataAdd = () => setDataAdd(!dataAdd);
  useEffect(() => {
}, []);

    return(
        <div className="content-body position-relative overflow-hidden">
            <div className="container-fluid">
				<div className="page-header main-header"> 
				<h1> Manage NDR </h1>
                
                    <Nav tabs className="master-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}
                            >
                                    <span className="text-white">Pending NDR</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                <span className="text-white">All Failed Reasons</span>
                            </NavLink>
                        </NavItem>
						
						 {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
						 
						 <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}
                            >
                                <span className="text-white">Action Taken</span>
                            </NavLink>
                        </NavItem>
						 
				   }	
					
                    </Nav>
                </div>
                <div className="master-content card border-0 shadow">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {activeTab === '1' &&
                                        <LiveNdr user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {activeTab === '2' &&
                                        <AllNdr user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
						 {(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
						<TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {activeTab === '3' &&
									 <ActionTaken user={props.user} /> 
                                    }
                                </Col>
                            </Row>
                        </TabPane>
						 }
                    </TabContent>
                </div>
            </div>
        </div>
    )
}
export default Ndr