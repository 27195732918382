import React, {useState} from 'react';
import { Row, Col, Card, CardBody, CardTitle, Spinner, FormGroup,Form, CardHeader, Label, Button, Table, Collapse } from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';

const PriceCalculator = (props) => {
    const [isOpenCod, setIsOpenCod] = useState(false);
    const [prepaid, setPrepaid] = useState(true);
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const [courierwithPrice, setCourierwithPrice] = useState(false);

    const toggleCod = () => {
        setIsOpenCod(!isOpenCod);
        if(prepaid){
            setPrepaid(!prepaid);
        }
    };
    const togglePrepaid = () => {
        setPrepaid(!prepaid);
        if(isOpenCod){
            setIsOpenCod(!isOpenCod);
        }
    };

    const { register, handleSubmit, formState: { errors }} = useForm();
    const onSubmit = (data) => {
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
        } else {
            data.admin_id = props.user[0].id
        }
        setLoader(true);
     axios({
       method: "post",
       url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
       data: data,
     })
       .then(function (response) {
        setLoader(false);
        if(response.data.error === false){
            setLoadertable(true);
            setCourierwithPrice(response.data.data)
          }
        if(response.data.error === true){
            Swal.fire({
              title: 'Error',
              text: response.data.msg,
              icon: 'error',
            })
          }
       })
       .catch(function (response) {
        setLoader(false);
        Swal.fire({title:'Error', text:response, icon:'error'})
       });
    }
    const onErrorUpdate = (errors, e) => console.log('Error', errors);
    return (
        <div className="content-body overflow-hidden mod_price_calculator">
      {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>
            <Card className="p--0 my-3 Create-Order shadow content-wrapper border-0">
              <Card className="border-0">
                <CardHeader className="d-flex justify-content-between">
                  <CardTitle className="mb-0 page-title">Price Calculator</CardTitle>
                </CardHeader>
                <CardBody className="forms">
                    <Form className="larg-form" onSubmit={handleSubmit(onSubmit, onErrorUpdate)}>                        
                      <Row className="mx-0">
                        <Col sm={12}>
                            <Card>
                                <CardBody className="pt-0">
                                    <Row>                                        
                                        <Col sm={12} md={8} lg={6} xl={5}>
                                            <CardHeader className="px-0"><CardTitle >Choose Delivery Type</CardTitle></CardHeader>
                                            <FormGroup>                                            
                                                <div className="d-flex">
                                                    <span className="mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="3" id="prepaid"  defaultChecked onClick={togglePrepaid}/>
                                                        <Label className="mx-2" for="prepaid">Prepaid</Label>
                                                    </span>
                                                    <span className="mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="1" id="cod"  onClick={toggleCod} />
                                                        <Label className="mx-2" for="cod">COD</Label>
                                                    </span>
                                                    <Collapse isOpen={isOpenCod}>
                                                        {isOpenCod &&
                                                        <input {...register("cod_value", { required: 'Please Enter COD Amount' })} className="form-control-sm form-control" placeholder="Enter COD Amount" id="cod_amount" style={{width: "170px"}}/>
                                                    }
                                                        </Collapse> 
                                                </div>
                                                {errors.cod_value && <span className="text-danger d-block mb-2">{errors.cod_value.message}</span>}
                                            </FormGroup>                                 
                                        </Col>
                                    </Row>   
                                    <hr/>
                                    <Row>
                                        <Col sm={12}><CardHeader  className="px-0"><CardTitle>Pincode Details</CardTitle></CardHeader></Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="source_pincode">Source Pincode</Label>
                                                <input {...register("source_pincode", { required: 'Please Enter Source Pincode', 
                                                minLength: { value: 6, message: "at least 6 digits"},
                                                maxLength: { value: 6, message: "maximum 6 digits"},
                                                pattern:{value:/^[0-9]+$/i,message:'Only numbers allowed'} })} id="source_pincode" className="form-control-sm form-control" placeholder="Enter Source Pincode" />
                                                {errors.source_pincode && <span className="text-danger d-block mb-2">{errors.source_pincode.message}</span>}
                                             </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="dest_pincodes">Destination Pincode</Label>
                                                <input {...register("destination_pincode", { required: 'Please Enter Destination Pincode', 
                                                minLength: { value: 6, message: "at least 6 digits"},
                                                maxLength: { value: 6, message: "maximum 6 digits"},
                                                pattern:{value:/^[0-9]+$/i,message:'Only numbers allowed'} })} className="form-control-sm form-control" placeholder="Enter Destination Pincode" id="destination_pincode" />
                                                {errors.destination_pincode && <span className="text-danger d-block mb-2">{errors.destination_pincode.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="prod_actual">Product Actual Weight In KGS.</Label>
                                                <input {...register("weight_in_kgs", { required: 'Product Actual Weight In KGS', 
                                                pattern:{value:/^[+-]?\d+(\.\d+)?$/,message:'Only decimal numbers allowed'} })} className="form-control-sm form-control" id="product_actual" />
                                                {errors.weight_in_kgs && <span className="text-danger d-block mb-2">{errors.weight_in_kgs.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="product_dimension">Product Dimension</Label>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <Label for="prod_l" className="mx-2">L</Label>
                                                        <input {...register("length_in_cms", { required: 'Please Enter Length', 
                                                        pattern:{value:/^[+-]?\d+(\.\d+)?$/,message:'Only decimal numbers allowed'} })} className="form-control-sm form-control " id="length_in_cms" />
                                                        {errors.length_in_cms && <span className="text-danger d-block mb-2">{errors.length_in_cms.message}</span>}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Label for="breadth_in_cms" className="mx-2">B</Label>
                                                        <input {...register("breadth_in_cms", { required: 'Please Enter Breadth', 
                                                        pattern:{value:/^[+-]?\d+(\.\d+)?$/,message:'Only decimal numbers allowed'} })} className="form-control-sm form-control " id="breadth_in_cms" />
                                                        {errors.breadth_in_cms && <span className="text-danger d-block mb-2">{errors.breadth_in_cms.message}</span>}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Label for="prod_h" className="mx-2">H</Label>
                                                        <input {...register("height_in_cms", { required: 'Please Enter Height', 
                                                        pattern:{value:/^[+-]?\d+(\.\d+)?$/,message:'Only decimal numbers allowed'} })} className="form-control-sm form-control " id="height_in_cms" />
                                                        {errors.height_in_cms && <span className="text-danger d-block mb-2">{errors.height_in_cms.message}</span>}
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Col>     
                                    </Row>                               
                                </CardBody>
                            </Card>
                        </Col>                        
                      </Row>                     
                      <Row className="mx-0 mb-4">
                      <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Get Courier Price</Button>
                      </Col>
                      </Row>
                    </Form>
                    {loadertable &&  
                    <>                
						<Row>
						<Col sm={12}>
						<CardBody>
						<Row>
						{Object.entries(courierwithPrice).length >0 && courierwithPrice !== undefined ? courierwithPrice.map((item, index) => (
						<Col md={4}>
						<div className="table_block">
						<ul>
						<li><strong>Courier</strong><span>{item.courier_name}</span></li>
						<li><strong>Charged Weight<br/><small> (In KGS.)</small></strong>
						  <span>{ item.charged_weight_in_kgs!=undefined || item.charged_weight_in_kgs!='' ? item.charged_weight_in_kgs : 'NA'}</span></li>
						  
						<li><strong>Price</strong> <span>{item.price.total || 'NA'}</span></li>
						<li><strong>TAT</strong><span>{item.tat_in_days || 'NA'}</span></li>
						</ul>
						</div>
						</Col>
						))
						:loadertable ? <div className="table_block">Loading...</div> : <div className="table_block">No Data Found</div> }
						</Row>
						</CardBody>
						</Col>
						</Row>
					  
                    </>
                    }
                </CardBody>                    
              </Card>
            </Card>
          </Col>          
          </Row>
           </div>
    );
}

export default PriceCalculator;