import React, {useState, useEffect} from 'react';
import {Card, Button, FormGroup,CardBody, Table, CardHeader,CardTitle,Form, Row, Col } from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import { changeIntoDateYmd } from '../../../../utlis';

// import axios from 'axios';
import axios from '../../../../CommonAxios';

import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faDownload} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const DailyShipmentSummary = (props) => {
    const [filterDate, setFilterDate] = useState([new Date(),new Date()]);
    const [summaryList, setSummaryList] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [datestate, setDateState] = useState({start: moment(),end: moment(),});
    const { start, end } = datestate;
    const handleCallback = (start, end) => {
        setDateState({ start, end });
      };
    const ranges = {
        startDate: start !== '' && start.toDate(),
        endDate: end !== '' && end.toDate(),
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, 'days').toDate(),
            moment().subtract(1, 'days').toDate(),
          ],
          'Last 7 Days': [
            moment().subtract(6, 'days').toDate(),
            moment().toDate(),
          ],
          'Last 30 Days': [
            moment().subtract(29, 'days').toDate(),
            moment().toDate(),
          ],
          'This Month': [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
        },
      }
    const listingSummary = () => {
        setLoadertable(true);
        // setDateState({start: moment(),end: moment(),});
        let dateStart = moment(datestate.start).format("YYYY-MM-DD");
        let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
        let data = {"start_date":datestate.start !== '' ? dateStart : '',"end_date":datestate.end !== '' ? dateEnd : ''}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DAILY_UPLOAD_SUMMARY}`,
            data: data,
          }).then((res) => {
            setLoadertable(false);
            if(res.data.error === false){
                setSummaryList(res.data.data);
                console.log('setSummaryList', setSummaryList)
            }
          }).catch((err) => {
            setLoadertable(false)
            if(String(err) === "Error: Network Error"){
                Swal.fire({icon:'error',html:'Network Error<br/>Please reload and try again!',title:'Error'});
            }else{
                Swal.fire({icon:'error',text:err,title:'Error'});
            }
          });
    }
    useEffect(() => {
        listingSummary();
    }, []);
    let merchantInfo = checkData(summaryList) && Object.values(summaryList.reduce((osi, item) => {
        let merchantid = item.merchant_id
        if (!osi[merchantid] && checkUndeNullBlank(item.merchantName)){
            osi[merchantid] = {merchant_id: merchantid,merchant_name:item.merchantName};
        }
        
        return osi;
    }, {}));
    let AllSummary = 0
    let courierInfo = checkData(summaryList) && Object.values(summaryList.reduce((osi, item) => {
        let courierid = item.courier_id
        if (!osi[courierid])
            osi[courierid] = {courier_id: courierid,courier_name:item.courierName, total: []};
        let total_count=checkUndeNullBlank(item.total) ? item.total : 0;
            osi[courierid].total.push(total_count); 
            AllSummary += total_count
        return osi;
    }, {}));
    let DailInfoTemp= Array();
    let dailySummaryInfo = checkData(summaryList) && Object.values(summaryList.reduce((osi, item) => {
        let courierid = checkUndeNullBlank(item.courier_id) && item.courier_id
        let merchantid = checkUndeNullBlank(item.merchant_id) && item.merchant_id
        let total = item.total
        if (parseInt(courierid)>=0 && parseInt(merchantid)>0 && !DailInfoTemp[merchantid]){
            var yo=[];
            var fileMapObj = {courierid,total};
            yo.push(fileMapObj);
            DailInfoTemp[merchantid] = yo;
            
        }else{
            if(DailInfoTemp[merchantid]!=undefined){
                var output=[];   
                if(Object.keys(DailInfoTemp[merchantid]).length>0){
                if(Array.isArray(DailInfoTemp[merchantid]) && DailInfoTemp[merchantid].length>0){
                    var fileMapObj = {courierid,total};
                    DailInfoTemp[merchantid].push(fileMapObj);
                }else{
                    var fileMapObj = {courierid,total};
                    DailInfoTemp[merchantid]=fileMapObj;
                }
                }else{
                    console.log('hii');
                }
            }
        }
        return osi;
    }, {}));
    function getKeyByValue(object, value) {
        console.log('object',object)
        let courierTotal=0;
        let a = Object.keys(object).forEach(key => {
           if(object[key].courierid!=undefined && object[key].courierid == value){
               courierTotal=object[key].total!=undefined?object[key].total:0;
           }
        });
        return courierTotal; 
      }
        
    return (
        <div className="DailyShipmentSummary">
            
            <Card className="border-0 shadow">
                <CardHeader className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <CardTitle className="page-title mb-3 mb-sm-0">Daily Shipment Summary</CardTitle>
                    <Form className="filter-form">   
                        <Row className="mx-sm-0 justify-content-end">
                            <Col sm={9}>
                            <FormGroup className='mb-sm-0'>
                                {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placement="bottomEnd" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="DD-MM-YYYY" /> */}
                                <DateRangePicker
                                    initialSettings={ranges}
                                    onCallback={handleCallback}
                                >
                                {datestate.start !== '' && datestate.end !== '' ? 
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                    {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                                </div>
                                :
                                <div className='form-control cursor-pointer d-flex align-items-center'>
                                    Select Date
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                </div>
                                }
                                </DateRangePicker>
                            </FormGroup>
                            </Col>
                            <Col sm={3} className="">
                                <Button className="ctm-btn btn-sm" color="primary" onClick={listingSummary}>Search</Button>
                            </Col>
                        </Row>
                    </Form>  
                </CardHeader>
                <CardBody className="p-0">
                <div className="">
                    <Table responsive borderless className="text-center customTable">
                        <thead>
                            <tr>
                                <th className="text-nowrap">Merchant Name</th>
                                <th className="text-nowrap">Total</th>
                                {checkData(courierInfo) && courierInfo.map((item, index) => {
                                   return(
                                    <th className="text-nowrap">{item.courier_name}</th>
                                    );
                                })}
                            </tr>                            
                        </thead>
                        <tbody>
                            <tr>
                                <th>Total Sum</th>
                                <th className="text-nowrap">{AllSummary}</th>
                                {checkData(courierInfo) && courierInfo.map((item, index) => {
                                   return(
                                    <th className="text-nowrap">{eval(item.total.join('+'))}</th>
                                    );
                                })}
                                 
                            </tr>

                                {checkData(merchantInfo) && merchantInfo.map((item, index) => {
                                    let totalTdVal = 0
                                   let shipSumVal = checkData(courierInfo) && courierInfo.map((courierItem, index) => {
                                        let summaryVal = getKeyByValue(DailInfoTemp[item.merchant_id], courierItem.courier_id)
                                        totalTdVal = totalTdVal+summaryVal
                                        return(
                                            <td className="text-nowrap test">{summaryVal}</td>
                                            );
                                        })
                                   return(
                                    <tr>   
                                       <td className="text-nowrap">{item.merchant_name}</td>
                                       <td>{totalTdVal}</td>
                                        {shipSumVal}
                                    </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                    </div>
                </CardBody>
            </Card>            
        </div>
    );
}

export default DailyShipmentSummary;