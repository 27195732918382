import React,{useEffect, useState} from 'react';
import {Card, Label, FormGroup, CardHeader, CardTitle, Button, CardBody, Row, Col } from 'reactstrap';
// import { DateRangePicker } from 'rsuite';
import { Bar } from 'react-chartjs-2';
// import axios from 'axios';
import axios from '../../../../CommonAxios';
import Swal from 'sweetalert2';

const CourierOverview = (props) => {
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [filterDate, setFilterDate] = useState([]);
    const [loadertable, setLoadertable] = useState(false);
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef =  dateRange => dateRangeRef = dateRange;
    const shipmentStatusData = () => {
        setLoadertable(true);
        let data = {}
        if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
            data.admin_id=props.user[0].id
          }else{
            data.merchant_id=props.user[0].id
          }
        axios({
            method: "post",
			headers: { "x-ref": "YjJjZmVlZTctZjc3Ny00ODU1LTllMTctYjBjZDYzNzQ0OGNl" }, // this line is added as on Oct 18,2023 //
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_OVERALL_PERFORMANCE}`,
            data: data,
          }).then((res) => {
            setLoadertable(false)
            if(res.data.error === false){
                setPerformanceOverviews(res.data.data);
            }
          }).catch((err) => {
            setLoadertable(false)
            Swal.fire({icon:'error',text:err,title:'Error'});
          });
    }
    useEffect(() => {
        shipmentStatusData();
    }, []);

    const data = {
        labels: ['10-02-2022', '11-02-2022', '12-02-2022'],
        datasets: [
          {
            label: 'Shipments Pending',
            data: [12, 30, 40],
            backgroundColor: [
            'rgba(255,225,0,0.5)',
            ],
        },
        {
          label: 'Shipments Delivered',
          data: [10, 50, 70],
          backgroundColor: [
            'rgb(138,227,117)',
          ],
          
        },
        {
            label: 'Shipments RTO Delivered',
            data: [5, 90, 40],
            backgroundColor: [
            'rgb(237,110,81)',
            ],
            
          },
          
        ],
       
        
      };

     

      const options = {
        indexAxis: 'y',
        responsive: true,
        scales: {
            x: {
                stacked: true,
                barThickness : 15
            },
            y: {
                stacked: true,
                barThickness : 15
            }
        },
        plugins: {
          title: {
            display: true,
            text: 'Courier Wise Status Performance'
          },
          legend: {
            display: false,
            position: "top",
         }
        },
        maintainAspectRatio: false 
       
      };

      
    return (
        <div className="CourierOverview">
            <Card className="border-0 shadow">
                <CardHeader className="page-header">
                    <Row>
                        <Col xl={4}>
                            <CardTitle className="page-title mb-xl-0">Courier Overview</CardTitle>
                        </Col>
                        <Col xl={8}>
                            <Row className="align-items-center justify-content-sm-end">
                                <Col xs={12} sm={6} lg={5}>
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-sm-0">
                                                <span className="form-check2 text-nowrap">
                                                    <input name="order_type" type="radio" id="overview_all" />
                                                    <Label for="overview_all">All</Label>
                                                </span>  
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-sm-0">
                                                <span className="form-check2 text-nowrap">
                                                    <input name="order_type" type="radio" id="overview_COD" />
                                                    <Label for="overview_COD">COD</Label>
                                                </span>  
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup className="mb-sm-0">
                                                <span className="form-check2 text-nowrap">
                                                    <input name="order_type" type="radio" id="overview_Prepaid" />
                                                    <Label for="overview_Prepaid">Prepaid</Label>
                                                </span> 
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>  

                                <Col xs={12} sm={6} lg={6}>
                                    <Row>
                                        <Col xs={8} sm={8} md={7} lg={8}>
                                            <FormGroup className="mb-0">
                                                {/* <DateRangePicker placeholder="Select Date" placement="bottomEnd" format="DD-MM-YYYY" showOneCalendar={props.oneCalendar} /> */}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <FormGroup className="mb-0">
                                                <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>                                   
                                                              
                                </Col> 
                            </Row>
                        </Col>                        
                    </Row>                    
                </CardHeader>
                <CardBody>
                <div style={{width: "100%",height: "300px"}}>
                    <Bar data={data} options={options}/>
                </div>
                </CardBody>
            </Card>            
        </div>
    );
}

export default CourierOverview;