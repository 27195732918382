module.exports = {
    account_type: {
        1:'Postpaid', 2: 'Prepaid',
    },
    yes_no: {
        0: 'No',1: 'Yes'
    },
    status: {
        0: 'InActive', 1: 'Active'
    },
    merchant_status: {
        0: 'Pending', 1: 'Updated'
    },
    service: {
        1: 'Express', 6:'Reverse', 7: 'Surface'
    },
    delivery_type_id: {
        1: 'COD', 3: 'Prepaid'
    },
    order_status : {
    1: 'Ordered',2: 'AWB Punched',3: 'AWB Failed',4: 'Order Pickup Pending',5: 'Order Pickup',6: 'Intransit',7: 'OFD',
    8: 'Order Cancelled',9: 'Shipment Cancelled', 10:  'Delivered', 11: 'Fail to Delivered', 12:  'RTO Intransit',
    13: 'Returned', 14:  'Heldup', 15: 'Lost', 16:  'Damaged', 17: 'Contact CC', 18:  'Arrived at Destination',
    19: 'Cancellation Requested', 20:  'RTO Undelivered', 21: 'Pickup Cancelled by Merchant', 22: 'Pickup Cancelled by Courier',
    23: 'Pickup Failed', 24: 'Cancelled',25: 'IN Process'
    },
    dash_card_order_status : {
    1: 'Ordered',2: 'AWB Punched',3: 'AWB Failed',4: 'Order Pickup Pending',5: 'Order Pickup',6: 'Intransit',7: 'OFD',
    10:  'Delivered', 11: 'Fail to Delivered', 12:  'RTO Intransit',
    13: 'Returned', 14:  'Heldup', 15: 'Lost', 16:  'Damaged', 17: 'Contact Customer Care', 18:  'Arrived at Destination',
    20:  'RTO Undelivered',
    },
    order_status_badge : {
        1: 'light', 2: 'light', 3: 'danger', 4: 'secondary', 5: 'light', 6: 'light', 7: 'light', 8: 'danger', 9: 'danger', 10: 'success', 11: 'warning', 12: 'warning',
        13: 'danger', 14: 'warning', 15: 'warning', 16: 'warning', 17: 'light', 18: 'light', 19: 'light', 20: 'light', 21: 'danger', 22: 'warning', 23: 'danger', 24: 'danger',25: 'warning'
    },
    states : {
        62: 'Arunachal Pradesh', 63: 'Assam', 64: 'Manipur', 65: 'Meghalaya', 66: 'Sikkim', 68: 'Nagaland', 69: 'Tripura', 70: 'Mizoram',
        72: 'Haryana', 73: 'Punjab', 75: 'Uttarakhand', 76: 'Himachal Pradesh', 77: 'Jammu and Kashmir', 78: 'Chandigarh',
        79 : 'Rajasthan', 81: 'Kerala', 82: 'Tamil Nadu', 83: 'Telangana', 84: 'Karnataka', 85: 'Andhra Pradesh', 86: 'Delhi', 87: 'Madhya Pradesh', 88: 'Uttar Pradesh', 89: 'Chhattisgarh', 90: 'Andaman and Nicobar',
        91: 'Lakshadweep', 92: 'Bihar', 93: 'Goa', 94: 'Gujarat', 95: 'Jharkhand', 96: 'Orissa', 97: 'West Bengal', 98: 'Maharashtra',
        111: '', 112: '', 113: '', 114: 'Uttaranchal', 115: 'Pondicherry', 116: 'ODISHA', 117: 'KHARSIA', 118: 'Dadra and Nagar Haveli', 119: 'Daman and Diu'
    },
    ship_method : {
        1: 'Custom', 2: 'Economical', 3: 'Performance'
    },
    weight: {
        1:'0.100', 2:'0.250', 3:'0.500', 4:'1.000', 5:'2.000', 6:'3.000', 7:'5.000', 8:'10.000', 9: '15.000', 10:'20.000', 11:'25.000' 
    },
	
	weightAdditional: {
        1:'0.100', 2:'0.250', 3:'0.500', 4:'1.000', 5:'2.000', 6:'2.500', 7:'5.000', 8:'10.000', 9: '15.000', 10:'20.000', 11:'25.000' 
    },
    movement_type: {
        1:'Forward', 2:'Reverse'
    },
    data_type_id: {
        1:'Courier Serviceability',2:'Address Labels',3:'Manifest', 4:'Shipment Data', 5:'Order Data', 6:'NDR Data', 7:'Channel Order',8:'Remittance Data', 9:'Freight Data', 10: 'SMS Log', 11: 'Courier Remittance', 12: 'Invoice', 13:'Credit Notes', 14:'Debit Notes', 15:'Utility Data', 16:'Lost Shipments',
        17:'Weight Recon.',18:"Passbook" 
    },
    wallet_head: {
        1:'Wallet Recharge', 2:'Shipped Order', 3:'VAS', 4:'Invoice Payment', 5:'Charge Back'
    },
    pay_status: {
        0: 'Failed', 1: 'Received'
    },
    zone_id : {
        1:'East',2:'West',3:'North',4:'South',5:'Central',6:'North East'
    },
    creditNote_head_id : {
        1:'Shipment Lost',2:'Excess Weight Charge(c) (With GST)',3:'Twice Freight Charge (With GST)',4:'Incorrect Freight Charge (With GST)'
    },
    debitNote_head_id : {
        1:'Status Reversal',2:'Legal Complained'
    },
    kyc_type_id: {
        1: 'Adhar Card', 2: 'Voter ID', 3: 'Driving License'
    },
    company_type_id: {
        1: 'Adhar Card', 2: 'Voter ID', 3: 'Driving License'
    },
    order_ndrs:{
        1:'Premise Lock',2:'Customer Not Available',3:'Customer Refused',4:'Cash Not Ready',5:'Customer Out of Station',6:'Wrong Address/Pin Code',7:'Address Correct but No Such Customer',
        8:'Requested Delay Delivery',9:'Restricted Entry',10:'Customer Wants Open Delivery',11:'Customer Requested Self Collection',13:'Out of Delivery Area (ODA)'
    },
    channel_order_status : {
        1: 'Added',2: 'Cancel',3: 'Ship'
    },
    ndr_actions:{
        1:'Retry',2:'Return'
    },
    pending_order_status : [5,6,7,14,15,16,17,18],
    overall_pending_order_status : [4,5,6,7,11,14,15,16,17,18],
    pending_order_status_pie_chart : [5,6,7,14,15,16,17,18],
    rto_order_status : [12,13],
    delivered_order_status : [10],
    failed_order_status : [11],
    ledger_heads:{
        1:'Remittance Adjust against Freight',2:'Remittance Paid',3:'Freight Paid'
    },
    zone_category_id:{
        1:'Metro',2:'ROI',3:'Within City',4:'Within Zone',5:'Special Destination',6:'Extended',7:'Within State'
    },
    download_delay : 5000,
    volumetricFormula : 5000,
    sms_log_status : {
        0:'Not Verified', 1:'Verified'
    },
    weight_mismatch_status : {
        1:'Discrepency', 2:'Dispute Raised', 3:'Accepted by Courier', 4:'Rejected by Courier', 5:'Auto Accepted', 6:'Accepted by Merchant'
    },
    crypto_key : 'ae6ea2e269599cc83ba2ed6b2925fdeb',
    baseString : 'b2cfeee7-f777-4855-9e17-b0cd637448ce',
	
	

	easyecom_account_users : {1:'Super admin',2:'vendor_admin',3:'vendor_order_process',4:'vendor_accounting',5:'marketplace_admin',6:'merchant_vendor',7:'vendor_inventory',8:'sales_team',9:'vendor_brand_owner',10:'vendor_return_handling',11:'Order Processing and Inventory',12:'B2B Customer',13:'B2B Salesman',14:'seller_mp_admin',15:'Custom',27:'company_admin',37:'Aggregator Location Admin',63:'Freight Forwarder',74:'Aggregator User Type',81:'Customer Support',84:'TC Category',86:'TC Ops',87:'TC Vendor',104:'B2B User',107:'Picker',125:'Warehouse staff',130:'Warehouse Manager',135:'Order Search',141:'Order Processing and Inventory (All Warehouse)',163:'Aggregator Admin',166:'marketplace Seller',175:'Client Admin',186:'Finance team',187:'Supply Chain',188:'Catalogue team',189:'Category',190:'Warehouse executive',195:'Finance teams',200:'Sales Sirona',205:'Area Sales Manager',206:'Regional Sales Manager'} , 
	
    easyecom_statecodes : { 'Andhra Pradesh': 'AP','Arunachal Pradesh'	:'AR','Assam'	:'AS','Bihar'	:'BH','Chhattisgarh'	:'CT','Goa'	:'GA','Gujarat'	:'GJ','Haryana'	:'HR','Himachal Pradesh'	:'HP','Jammu & Kashmir'	:'JK','Jharkhand'	:'JA','Karnataka'	:'KA','Kerala'	:'KL','Madhya Pradesh'	:'MP','Maharashtra'	:'MH','Manipur'	:'MN','Meghalaya'	:'ME','Mizoram'	:'MI','Nagaland'	:'NL','Odisha'	:'OR','Punjab	'	:'PB','Rajasthan'	:'RJ','Sikkim'	:'SK','Tamil Nadu'	:'TN','Telangana'	:'TS','Tripura'	:'TR','Uttar Pradesh'	:'UP','Uttarakhand'	:'UT','West Bengal'	:'WB','Delhi'	:'DL','Puducherry'	:'PY','Andaman & Nicobar Islands'	:'AN','Chandigarh'	:'CH','Dadra & Nagar Haveli'	:'DN','Daman & Diu'	:'DD','Lakshadweep'	:'LD' }
	
   
}