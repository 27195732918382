import React,{useState,useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Spinner } from 'reactstrap';
import { Link,useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import Logo from "../../assets/images/logo.png";
import AgreementPdf from '../../assets/agreement-for-website.pdf';
import { useForm } from 'react-hook-form';
import { SignupMerchant } from '../../actions';
import { connect, useDispatch } from "react-redux";
import Footer from '../Footer';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga';

const Thanks = (props) => {
    //const [passwordType, setPasswordType] = useState(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const history = useHistory();
  
  
	useEffect(() =>{
		 setTimeout(()=> history.push("/"),5000);
	}, [])

    //const { register:signupInput, handleSubmit:signupSubmit, formState: { errors }} = useForm();
	
	
    const onMerchantSignup = (data) => {
       /*  data.admin_id = process.env.REACT_APP_ADMIN_ID
        setLoader(true);
		
		// console.log('current data is '+data.admin_id) ; 
	 	
        dispatch(SignupMerchant(data)).then(response => {
            setLoader(false)
            if(Object.entries(response).length >0 ){
                if(response.error === false){
                Swal.fire({icon:'success', text:response.msg, title:'Success',allowOutsideClick: false,allowEscapeKey: false})
                .then(okay => {
                    if (okay) {
                        history.push("/");
                   }
                 });
                }else{
                    Swal.fire({icon:'error', text:response.msg, title:'Error'});
                }
            }else{
                Swal.fire({icon:'error', text:response.msg, title:'Error'});
            }
          }).catch(function (response) {
              console.log('resss', response)
            setLoader(false)
            Swal.fire({title:'Error', icon:'error', text:response})
          }); */
    };
      const onError = (errors, e) => Swal.fire({title:'Error', text:errors, icon: 'error'});
    return (


        <div className="app-content m-0 p-0 content overflow-hidden">
		<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NX6FQZMK" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
			<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11475314075"></script>
	<script id="gtag">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', 'AW-11475314075');
        `}
      </script>

        {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Col sm={12} lg={12} className="d-lg-flex align-items-center auth-left p-0 auth-bg position-relative">
                        <div className="d-lg-flex justify-content-center align-items-center w-100 credintials">
                            
                            <Card className="border-0 px-xl-3 mx-auto col-md-8 col-lg-6">
                            <CardHeader className="my-3">
                            <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3"><img className="img-fluid logoimg" src={Logo} alt="img" /></CardTitle></Link>
                            </CardHeader>
                              <CardTitle tag="h4">Dear Valued Customer,</CardTitle>
                            <CardBody className="align-items-center py-3">
							<Row className='justify-content-center'>
                              <p className="card-text">Thank you for choosing Tekipost! We're thrilled to welcome you to our platform and excited to embark on this journey with you.</p>
							  <p className="card-text">Your registration means a lot to us, and we can't wait to show you all the amazing features and opportunities that Tekipost has to offer.</p>
							  <p className="card-text"> We'll be reaching out to you shortly to provide any assistance you may need and to guide you through the next steps.
</p>
							  <p className="card-text">In the meantime, if you have any questions or need immediate assistance, please don't hesitate to contact us at hello@tekipost.com or give us a call at 9958900770. We're here to help!</p>
							  
							  <p className="card-text">Once again, thank you for choosing Tekipost. We're looking forward to helping you achieve your goals and make the most out of our platform.</p>
							  <p></p>
							  <p className="card-text">Best Regards,</p>
							  <p className="card-text">Team Tekipost.</p>
							 
							 </Row>
                         
                            </CardBody>
                        </Card> 
                        </div>
                        <div className="bg-overlay bg-primary"></div>
                        <ul className="bg-bubbles">
                            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                        </ul>
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
export default Thanks