import React, {useState, useEffect} from 'react';
import { PDFDocument } from 'pdf-lib'
import { Row, Col, Card, CardBody, Button, CardTitle, CardHeader, Table, Pagination, PaginationItem, PaginationLink,Label, Form, FormGroup, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Spinner, Input} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import Swal from 'sweetalert2';

// import axios from 'axios';
import axios from '../../CommonAxios';

import { download_delay, states } from '../../config';
import { changeIntoDateTime, changeIntoDateYmd, getPickupDate } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

const PickupPending = (props) => {

  const [modalDownload, setModalDownload] = useState(false);
  const toggleDownload = () => setModalDownload(!modalDownload);  
  const [PickupPending, setPickupPending] = useState({});
  const [PickupPendingRapid, setPickupPendingRapid] = useState({})
   
  const [filterDate, setFilterDate] = useState('');
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1);
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [dataLength, setDataLength] = useState(0) ; 
  
  const [datestate, setDateState] = useState({start: moment().subtract(29, 'days'),end: moment(),});
  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };
  // let dateRangeRef =  dateRange => dateRangeRef = dateRange;
  const history = useHistory();

  const pickupPendingList = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    setFilterInput('')
    setCurrentCount(1)
    setPage(1);
    setFilterDate('');
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");

    let data = {"order_status_id":[2,4], "start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    axios.post(process.env.REACT_APP_PICKUP_PENDING, data)
    .then((res) => {
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          setPickupPending(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    })
  }
  
  const rappidxOrderList = () => {
    setDateState({start: moment().subtract(29, 'days'),end: moment(),})
    setFilterInput('')
    setCurrentCount(1)
    setPage(1);
    setFilterDate('');
    let dateStart = moment().subtract(1, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");

    let data = {"start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
    
	 axios.post(process.env.REACT_APP_PICKUP_PENDING_RAPPIDX, data)
    .then((res) => {
      setLoader(false)
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          // console.log('herre  ' + JSON.stringify(res.data) ) ;
		  if(res.data.totalLength!=undefined && res.data.totalLength > 0) {
			setDataLength(res.data.TotalRows) ;
			setPickupPendingRapid(res.data.data) ;
		  }
		  // console.log('length is '+res.data.totalLength ) ;
		  // console.log('here1   '+res.data.data[0][0].amazon_label) ;
		  //console.log(' here 2  '+res.data.data[1])
		 // console.log(' here 3  '+res.data.data[2])
		  
        }
      }
    }).catch((err) => {
      setLoader(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    }) 
  }
  
  useEffect(() =>{
    setLoader(true)
    pickupPendingList();
	rappidxOrderList() ;
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = {"page":1, "per_page":event.target.value};
    onpaginationFilter(filterInput,pagePerpage)
  }
  function nextPageData() {
  if(checkData(PickupPending.data)&&checkUndeNullBlank(PickupPending.totalPage)){
    // &&checkUndeNullBlank(PickupPending.totalPage).length>page.length
      if(PickupPending.totalPage > page){
        setCurrentCount(currentCount+Object.entries(PickupPending.data).length)
    setPage(page+1)
    setLoader(true)
      let pagePerpage = {"page":page+1, "per_page":selectperpage};
      onpaginationFilter(filterInput,pagePerpage);
    }
  }
  }
  function previousPageData() {
  if(page >1){
  setPage(page-1)
  checkData(PickupPending.data)
  if(currentCount>Object.entries(PickupPending.data).length){
    setCurrentCount(currentCount-selectperpage)
  }else{
    setCurrentCount(1)
  }
  let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
  }
  // filter Bulk Order
  const {handleSubmit:filterPickupPending} = useForm();
  const onpaginationFilter = (data,pagePerpage) => {
    onCommonFilter(data,pagePerpage)
  };
  const onFilterPickupPending = (data,pagePerpage) => {
    pagePerpage.page=1
    if(checkUndeNullBlank(datestate)){
    setCurrentCount(1);
    setSelectperpage(selectperpage);
    setFilterInput(data);
    onCommonFilter(data,pagePerpage)
  }
  
  };
  const onCommonFilter = (data,pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    let byData = {}

    byData={"start_date":dateStart,"end_date":dateEnd}
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      byData.admin_id = props.user[0].id
    }else{
      byData.merchant_id = props.user[0].id
    }
      axios({
        method: "post",
        url: `${process.env.REACT_APP_PICKUP_PENDING}?page=${checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
        data: byData,
      }).then((res) => {
        setLoader(false);
        if(res.data !== undefined && res.data.fatal !== true){
          if(parseInt(res.data.errno)>0){
            if(res.data.sqlMessage!==undefined){
              Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
            }
          }else{
            if(res.fatal !== true && res.error !== true){
              setPickupPending(res.data);
            }
            else{
              Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
            }
          }
        }
      }).catch((err) => {
        Swal.fire({icon:'error',text:err,title:'Error'});
        setLoader(false);
      });
  };
  
  const { register:bulkLabel, handleSubmit:bulkLabelDownload} = useForm();
  const singleManifestDownload = (data) => {
    manifestDownloadCommon(data);
  }
  
  const manifestDownloadCommon = (data) =>{
    setLoader(true);
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    axios({
    method: "post",
    url: `${process.env.REACT_APP_DOWNLOAD_MANIFEST}`,
    data: data,
  }).then((res) => {
        if(res.data.is_generate === 1){
            setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
            },download_delay);
        }else{
          setLoader(false);
          Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
        }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
}
const singleLableDownload = (data) => {
  labelDownloadCommon(data);
}



const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
    
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const allRappidLableDownload = async function(data) {
	
	let total = data.length ; 
	
	const pdfDoc = await PDFDocument.create();
 
	for(var i=0;i<total;i++) {
		try {
  	  
		var type ='' ; 
		  
		var lbl =  data[i].amazon_label ; // btoa(data[i].amazon_label) ; // 
		
		const page = pdfDoc.addPage() ;
		
		//if(lbl.includes('PNG')) {
			const pngImage = await pdfDoc.embedPng(lbl) ;
			const pngDims = pngImage.scale(0.4) ;
			type = 'PNG' ;
	 
			page.drawImage(pngImage, {
			x: page.getWidth() / 2 - pngDims.width / 2 + 2,
			y: page.getHeight() / 2 - pngDims.height + 390,
			width: pngDims.width,
			height: pngDims.height,
			})
		//}
		 
 
		} catch(error) {
			
		} 
	}
  
	 const pdfBytes = await  pdfDoc.saveAsBase64() ;
	
	var winparams = 'scrollbars=yes,menubar=yes,resizable,screenX=50,screenY=50,width=850,height=1050';
	var printWindow = window.open ("", "_blank", winparams);
	
	var htmlPop = '<embed width=100% height=100%'
			 + ' type="application/pdf"'
			 + ' src="data:application/pdf;base64,'
			 + escape(pdfBytes)
			 + '"></embed>'; 
	printWindow.document.write(htmlPop);
	
	 
}


  const labelDownload = (data) =>{
        setLoader(true);
        data.awb_nos = data.awb_nos.split(',')
    labelDownloadCommon(data);
  }
  const labelDownloadCommon = (data) =>{
    setLoader(true);
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = 1
    }else{
      data.merchant_id = props.user[0].id
      data.logo = props.user[0].logo
    }
    axios({
    method: "post",
    url: `${process.env.REACT_APP_DOWNLOAD_ADDRESS_LABEL}`,
    data: data,
  }).then((res) => {
        if(res.data.is_generate === 1){
          setTimeout(function(){ 
              setLoader(false);
              history.push("/my-downloads"); 
          },download_delay);
        }
        else{
          setLoader(false);
          Swal.fire({title: 'Error',text: res.msg,icon: 'error',});
        }
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
    setLoader(false);
  });
}
  

  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 PickupPending content-wrapper border-0 mb-3 p-3">
          <Card className="border-0"> 
				<div className="card-title title_icon">
				<i className="bi bi-card-heading"></i> 
				Manage Pickup Pending  
				</div>
			
			 
			 
              <Form className="filter-form" onSubmit={filterPickupPending(onFilterPickupPending)}>
                <Row className="mx-0 pt-1 mb-3 mb-md-0 justify-content-center">
                  <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                          {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} /> */}
                          <DateRangePicker
                            initialSettings={ranges}
                            onCallback={handleCallback}
                          >
                          <div className='form-control cursor-pointer d-flex align-items-center'>
                            {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                          </div>
                        </DateRangePicker>
                      </FormGroup>
                  </Col>
                  <Col sm={3} className="filter-btn">
                    <Button className="btn btn-sm mx-2" color="primary">Search</Button>
                    <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={pickupPendingList}>Reset</Button>
                    <Button className="btn btn-sm mx-2" color="primary" onClick={toggleDownload}>Bulk Label</Button>
                  </Col>
                </Row>    
              </Form>          
              
			  
			  <Modal isOpen={modalDownload} toggle={toggleDownload} size="lg" scrollable={true}>
                <ModalHeader toggle={toggleDownload}>Manual Address Label Generation Module</ModalHeader>
                <ModalBody>
                  <Form onSubmit={bulkLabelDownload(labelDownload)}>
                    <Row className="mx-0 justify-content-center downloadModal">
                      <Col sm={12}>
                          <FormGroup>
                            <Label for="exampleText">Please Enter Comma Seperated AWB Number</Label>
                            <textarea {...bulkLabel("awb_nos", {required : 'Please Enter Comma Seperated AWB Number'})} rows="4" className="form-control-sm form-control" placeholder="Please Enter Comma Seperated AWB Number" id="labels" />
                            {/* <Input type="textarea" name="text" id="exampleText" rows="15" /> */}
                            <span className="small" > 500 Docket Numbers (aprox) or 5000 Characters Allowed</span>
                          </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Generate/Download Labels</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <CardBody className="p-0">
              {checkData(PickupPending) && checkData(PickupPending.data) && checkUndeNullBlank(PickupPending.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(PickupPending.data).length)} of {PickupPending.TotalRows} entries
                    </div>
                      {PickupPending.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Row className="m-0">  
                  {checkData(PickupPending.data) && PickupPending.data[0].courier_id > 0 ? PickupPending.data.map((item, index) => (
                    
                    <Col xs={6} sm={4} md={4} lg={4} className="mb-4 mb-xl-0 px-3">
                        <Card className="text-left" style={{minHeight : "80px"}}>
                            <CardBody className="py-0">
                                <h4 className="my-3"><Link to='#'>{item.totalOrders}</Link></h4>
                                <p className="text-muted font-weight-500 m-0" id="addressTooltip-1">
                                <span>{item.MerchantPickupPointName}</span> (
                                {item.pickup_address_1})
                                </p>
                                <UncontrolledTooltip placement="top" target="addressTooltip-1">
                                  {item.pickup_address_1} {item.pickup_city_name} {item.pickup_pincode} {states[item.pickup_state_id]}
                                </UncontrolledTooltip>
                                <p className="text-muted font-weight-500 m-0">{item.CourierName}</p>
                                <div><Link to="#" onClick={() => singleManifestDownload({"pickup_point_id":item.pickup_point_id,"courier_id":item.courier_id,"order_date":changeIntoDateYmd(item.created_at)})}>Generate Manifest</Link></div>
                                <div><Link to="#" onClick={() => singleLableDownload({"pickup_point_id":item.pickup_point_id,"courier_id":item.courier_id,"order_date":changeIntoDateYmd(item.created_at)})}>Download Address Labels</Link></div>
                                <div>Uploaded Date {changeIntoDateTime(item.created_at)}</div>
                                <div className="text-primary">Pickup Scheduled for- {getPickupDate(item.created_at)}</div>
                            </CardBody>
                        </Card>
                    </Col>
                    )) :''
                     }
                     </Row>
					 
					 
                {checkData(PickupPending) && checkData(PickupPending.data) && checkUndeNullBlank(PickupPending.TotalRows) ?
                <>
                {PickupPending.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(PickupPending.data).length)} of {PickupPending.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
            </Card>
			
			 
			<CardBody>
				<Row className="m-0"> 
				 
				 { checkData(PickupPendingRapid)  &&
				  
				  <React.Fragment>
				  
						{PickupPendingRapid.map(d => (  

						<Col xs={6} sm={4} md={4} lg={4} className="mb-4 mb-xl-0 px-3">
						<Card className="text-left" style={{minHeight : "80px"}}>
						<CardBody className="py-0">
						<h4 className="my-3"><Link to='#'>{dataLength}</Link></h4>
						<p className="text-muted font-weight-500 m-0">Amazon</p>
						<p className="text-muted font-weight-500 m-0">{d[0].warehouse_name}</p>	
						<div><Link to="#" onClick={() => singleManifestDownload({"pickup_point_id":d[0].pickup_point_id,"courier_id":d[0].courier_id,"order_date":changeIntoDateYmd(d[0].ordercreated)})}>Generate Manifest</Link></div>

						<div><Link to="#" onClick={() => allRappidLableDownload(d)}>Print All Labels</Link></div>
						<div>Uploaded Date {changeIntoDateTime(d[0].created_at)}</div>

						<div className="text-primary">Pickup Scheduled for- {getPickupDate(d[0].created_at)}</div> 

						 
						</CardBody>
						</Card>
						</Col>

						))}
			 		
					</React.Fragment>
					
					 
                     }
				  
                  
                </Row>	
			</CardBody>
                   
        </Card>
      </Col>
      
      </Row>
       </div>
  );
}

export default PickupPending;