import React, { useState, useEffect } from 'react';
import { toast,ToastContainer } from 'react-toastify';
import { Row, Col, Card, CardBody, Button, CardTitle, Collapse, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, Spinner, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Input } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faTimes, faArrowsAltH, faChevronDown, faPlus, faChevronRight, faDownload, faCartPlus, faChevronLeft, faFilter } from '@fortawesome/free-solid-svg-icons';
// import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import axios from '../../CommonAxios';
import { changeIntoDate, changeIntoDateYmd } from '../../utlis';
// import EditBulkOrders from './EditBulkOrders';
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { connect } from 'react-redux';
 
import { delivery_type_id, download_delay, zone_category_id} from '../../config';

const ActionTaken = (props) => {

  const [deliveryType, setDeliveryType] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [allOrder2, setAllOrder2] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [cancelmodal, setCancelmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [filterDate, setFilterDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [datestate, setDateState] = useState({ start: moment().subtract(29, 'days'), end: moment(), });
  //const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const dropFilterCount = 20
  const { start, end } = datestate;
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }
  const history = useHistory();

  // const onDateChange = (e) => {
  //   setFilterDate(e.text);
  // };

  const selectAll = (value) => {
    let abc = checkData(allOrder2.data) && allOrder2.data.map((key) => (
      key === false
    ));
    if (value) {
      // checkData(allOrder.data)&&allOrder.data.map((item) => (
      // setCheckboxOrderId(item)
      var opt_order_id = [];
      checkData(allOrder2.data) && Object.keys(allOrder2.data).map(function (key) {
        let tempObj = allOrder2.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };

  const toggleCheck = (utilityCheckbox, event, Id) => {
    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }

 

  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
      .then((res) => {
        setLoadertable(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setOrderDetail(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false)
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      })
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    orderReset();
  }

  const cancelmodalToggle = () => {
    setCancelmodal(!cancelmodal);
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  useEffect(() => {
    setLoadertable(true)
    bulkOrderListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //listing bulk Order
  const bulkOrderListing = () => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setDateState({ start: moment().subtract(29, 'days'), end: moment(), })
    let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
    let dateEnd = moment().format("YYYY-MM-DD");
    let data = { "order_status_id": 1, "start_date": dateStart, "end_date": dateEnd }
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setIsExport(false); reset(); setFilterDate(''); setDeliveryType([]); setSearchValue('');

    axios.post(process.env.REACT_APP_ACTION_TAKEN_SHIPMENTS,  data )
      .then((res) => {
        setLoadertable(false)
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            setAllOrder2(res.data);
          }
        }
      }).catch((err) => {
        setLoadertable(false)
        Swal.fire({ icon: 'error', text: err, title: 'Error' });
      })
  }

  const { register: uploadOrderdata, handleSubmit: orderUploadSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();
  
   
  const exportShipment2 = () => {
    if (isExport) {
      let data = filterInput
      if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      //data.order_status_id = 1
      data.is_export = 1
      axios.post(process.env.REACT_APP_ACTION_TAKEN_SHIPMENTS, data)
        .then((res) => {
          setLoader(false)
          if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
            if (parseInt(res.data.errno) > 0) {
              if (res.data.sqlMessage !== undefined) {
                Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
              }
            } else {
              if (res.data.is_export === 1) {
                setTimeout(function () {
                  setLoader(false);
                  history.push("/my-downloads");
                }, download_delay);
              }
            }
          }
        }).catch((err) => {
          setLoader(false)
          Swal.fire({ icon: 'error', text: err, title: 'Error' });
        });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  function handleSelectChange(event) {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    if (checkData(allOrder2.data) && checkUndeNullBlank(allOrder2.totalPage)) {
      // &&checkUndeNullBlank(allOrder.totalPage).length>page.length
      if (allOrder2.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allOrder2.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    if (page > 1) {
      setPage(page - 1)
      checkData(allOrder2.data)
      if (currentCount > Object.entries(allOrder2.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterOrderSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    onCommonFilter(data, pagePerpage)
  };
  const onFilterOrder = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setIsExport(true);
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkUndeNullBlank(datestate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    let dateStart = moment(datestate.start).format("YYYY-MM-DD");
    let dateEnd = moment(datestate.end).format("YYYY-MM-DD");
    let byData = {}
    let tmpDeliveryType = []


    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    byData = { "order_status_id": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? data.search_value : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "start_date": dateStart, "end_date": dateEnd }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ACTION_TAKEN_SHIPMENTS}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllOrder2(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
      setLoader(false);
    });
  };


   
  const onFilter = () => setIsOpen(!isOpen);


  return (
  
	<div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
        <ToastContainer className="copied-con" />
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 master-content content-wrapper border-0">
		<div className="page-header content-header">
		
		<div className="card-title title_icon">
		<i className="bi bi-card-heading"></i> 
		Action Taken on NDR
	 
		<div className="otherlinks">
			<div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
				<div className="btn-text btn-light" onClick={exportShipment2}>
				<span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span>
				</div>
				<div className="btn-text btn-light mx-2" onClick={onFilter}>
				<span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span>
				</div>
				
		  </div>
		</div>
		</div>
	
	
		
		<Collapse isOpen={isOpen}>
          <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="filter-form">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center justify-content-lg-center">
              <Col sm={6} md={5} lg={5} xl={4}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="position-relative p-0">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select {...filter("search_field")} className="form-control-sm form-control rounded-right-0" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                          <option value="">Search By</option>
                          <option value="customer_name">Customer Name</option>
                          <option value="customer_name">Customer Pincode</option>
                          <option value="customer_mobile">Customer Mobile</option>
                          <option value="order_no">Order No</option>
                          <option value="product_name">Product Name</option>
                        </select>
                      </InputGroupText>
                    </InputGroupAddon>
                    <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm={6} md={3} lg={3} xl={2}>
                <FormGroup>
                  <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                  <select {...filter("delivery_type_id")} className="form-control-sm form-control">
                    <option value="">Delivery Type</option>
                    {Object.entries(delivery_type_id).map(([key, value]) => (
                      <option value={key}>{value}</option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col sm={6} md={4} lg={3} xl={3}>
                <FormGroup>
                  {/* <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} /> */}
                  <DateRangePicker
                    initialSettings={ranges}
                    onCallback={handleCallback}
                  >
                    <div className='form-control cursor-pointer d-flex align-items-center'>
                      {moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
                    </div>
                  </DateRangePicker>
                </FormGroup>
              </Col>
              <Col sm={3} className="filter-btn">
                <Button className="btn btn-sm" color="primary">Search</Button>
                <Button type="reset" className="btn btn-sm mx-2" color="primary" outline onClick={bulkOrderListing}>Reset</Button>
              </Col>
            </Row>
          </Form>
        </Collapse>
        <CardBody className="p-0">
          {checkData(allOrder2) && checkData(allOrder2.data) && checkUndeNullBlank(allOrder2.TotalRows) ?
            <>
              <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                <div className="showing-count">
                  Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder2.data).length)} of {allOrder2.TotalRows} entries
                </div>
                {allOrder2.TotalRows > dropFilterCount ?
                  <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                        <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  : ''}
              </div>
            </>
            : ''}
	    </CardBody>
		
          <Table responsive borderless className="text-center customTable">
            <thead>
              <tr>
                <th><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /></th>
                {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                  <th>Merchant Name</th>
                }
                <th>Warehouse </th>
                <th>Order No</th>
                <th>Delivery</th>
                <th>Customer Details </th>
                <th className="col-2">Product</th>
                <th>Action Taken </th>
              </tr>
            </thead>
            <tbody>
              {checkData(allOrder2.data) ? allOrder2.data.map((item, index) => (
                <tr>
                  <td><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} /></td>
                  {props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin" &&
                    <td>{item.merchantName}<br />{item.merchantBrandName}</td>
                  }
                  <td>{item.MerchantPickupPointName}</td>
                  <td><Link to={`/order-detail/${item.id}`} from={"a"}>{item.order_no}</Link></td>
                  <td>{delivery_type_id[item.delivery_type_id]}</td>
                  <td>{item.customer_name}<br />{item.customer_mobile}</td>
                  <td><div className="text-truncate" id={`MoreProduct${item.id}`}>{item.product_name}</div>
                    <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                      {item.product_name}
                    </UncontrolledTooltip>
                  </td>
                  <td className="text-nowrap" width="300">{(item.remarks !== undefined && item.remarks !== null ? item.remarks : 'NA')}</td>
                </tr>
              )) :
                loadertable ? <tr>
                  <td colSpan="8">Loading...</td>
                </tr>
                  : <tr><td colSpan="8" className="text-center">No Data Found</td></tr>
              }
            </tbody>
          </Table>
		  
		  <br/> <br/>
          {checkData(allOrder2) && checkData(allOrder2.data) && checkUndeNullBlank(allOrder2.TotalRows) ?
            <>
              {allOrder2.TotalRows > dropFilterCount ?
                <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                  <div className="showing-count">
                    Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder2.data).length)} of {allOrder2.TotalRows} entries
                  </div>
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
                : ''}
            </>
            : ''}
			
			 <br/> <br/>
			  <br/> <br/>
			  
	 </div></Card></Col></Row></div> 
	
	
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}


export default connect(mapDispatchToProps)(ActionTaken);

