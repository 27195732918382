import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle,Collapse, Input,Badge,FormGroup,UncontrolledTooltip, InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faArrowUp, faChevronLeft, faChevronRight, faFilter } from '@fortawesome/free-solid-svg-icons';
import { delivery_type_id, download_delay, order_ndrs,  } from '../../config';
import { connect } from 'react-redux';
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import { Copy } from 'react-feather';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { copyToClipBoard, changeIntoDate,moreText } from '../../utlis';
import { toast,ToastContainer } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const toggleTakeAction = (id) => {
 
} 
const toggleTakeActionClose = (id) => {
 
} 
const toggleUploadNDR = () => {
 
} 






const AllNdr = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [liveNdrList, setLiveNdrList] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [isExport, setIsExport] = useState(false);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropFilterCount = 20;
  const history = useHistory();

  const [datestates, setDateStates] = useState({ start: moment().subtract(29, 'days'), end: moment(), });
   
   const handleCallbacks = (start, end) => {
    setDateStates({ start, end });
  };
  
  const { start, end } = datestates;
  
  const [ndrStatus, setNdrStatus] = useState([]);
  
  const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }



  const NdrStatusMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "NDR Status"
  }
  const opt_ndrStatus = [];
  for (const property in order_ndrs) {
    let tempObj = {label: `${order_ndrs[property]}`, value: property };
    opt_ndrStatus.push(tempObj);
  }

  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  const AllNdrList = () => {
  let data = {}
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
  setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${page}&per_page=${selectperpage}`,
      data: data,
    }).then((res) => {
      // console.log('rees', res)
      setLoadertable(false)
        if(res.data.error === false){
          setLiveNdrList(res.data);
        }else{
          Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoadertable(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }
  useEffect(() => {
    AllNdrList();
  }, []);
// copy text
const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}
function handleSelectChange(event) {
  setSelectperpage(event.target.value);
  setLoadertable(true)
  setPage(1)
  setCurrentCount(1)
  let pagePerpage = {"page":1, "per_page":event.target.value};
  onpaginationFilter(filterInput,pagePerpage)
}
function nextPageData() {
if(checkData(liveNdrList.data)&&checkUndeNullBlank(liveNdrList.totalPage)){
  // &&checkUndeNullBlank(liveNdrList.totalPage).length>page.length
    if(liveNdrList.totalPage > page){
      setCurrentCount(currentCount+Object.entries(liveNdrList.data).length)
  setPage(page+1)
  setLoadertable(true)
    let pagePerpage = {"page":page+1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
}
}
function previousPageData() {
if(page >1){
setPage(page-1)
checkData(liveNdrList.data)
if(currentCount>Object.entries(liveNdrList.data).length){
  setCurrentCount(currentCount-selectperpage)
}else{
  setCurrentCount(1)
}
let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
  onpaginationFilter(filterInput,pagePerpage);
}
}
const { register:filter, handleSubmit:filterNdrListSubmit, reset } = useForm();
const onpaginationFilter = (data,pagePerpage) => {
  onCommonFilters(data,pagePerpage)
};
const onFilterNdrList = (data,pagePerpage) => {
  setIsExport(true);
  data.start_date = datestates.start ; 
  data.end_date = datestates.end ; 
  pagePerpage.page=1
  if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(ndrStatus) || checkData(allCourier) || checkUndeNullBlank(datestates.start) ){
  setCurrentCount(1);
  setSelectperpage(selectperpage);
  setFilterInput(data);
  onCommonFilters(data,pagePerpage);
}
};
const onCommonFilters = (data,pagePerpage) => {
  let byData = {}
  let tmpNdrStatus = [];
  let courierId = [];
  let merchantsId = [];
  if(checkData(ndrStatus)){
    ndrStatus.map((item) => (
      tmpNdrStatus.push(item.value)
    )); 
  } 
  if(checkData(allCourier)){
    allCourier.map((item) => (
      courierId.push(item.value)
    )); 
  } 
  
  if(checkUndeNullBlank(courierId)){
    byData.courier_id=courierId
  }
  setLoader(true)
  if(data === undefined){
    data = filterInput
  }
   
   let ds =  moment(start).format("YYYY-MM-DD") ;
   let de =  moment(end).format("YYYY-MM-DD") ;
 
  byData={"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"ndr_status":checkUndeNullBlank(tmpNdrStatus)?tmpNdrStatus:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"start_date": ds, "end_date": de}
  
  setFilterInput(byData);
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
    byData.admin_id = props.user[0].id
  }else{
    byData.merchant_id = props.user[0].id
  }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setLiveNdrList(res.data);
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setLoader(false);
    });
};
const exportAllNdr = () => {
  if(isExport){
    let data = filterInput
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    data.is_export=1
      setLoader(true);
      axios.post(process.env.REACT_APP_ALL_NDR, data)
  .then((res) => {
    setLoader(false)
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.is_export === 1){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
        }
      }
    }
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
  }else{
    Swal.fire({icon:'error',title:'Error', text:'Please Choose Filter'})
  }
}

const onFilter = () => setIsOpen(!isOpen);


  return (
    <Row>
       <Col sm={12}>
        {loader && <div className="formLoader"><Spinner /></div>}
        <ToastContainer className="copied-con" />
        <Card className="p-0 my-3 master-content content-wrapper border-0">
		<div className="page-header content-header">
          	
	<div className="card-title title_icon">
	<i className="bi bi-card-heading"></i> 
	 Manage All NDR<br/><small className="fsc-2">Pending Shipments NDR</small>   
	  
	  <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light" onClick={exportAllNdr}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export NDR</span>
                </div>
                <div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
       </div>
      </div>
	 

			
			  
            <Collapse isOpen={isOpen}>
                <Form onSubmit={filterNdrListSubmit(onFilterNdrList)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value=''>Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value= "customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text"  className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_ndrStatus}
                              value={ndrStatus}
                              onChange={setNdrStatus}
                              labelledBy="NDR Status"
                              overrideStrings={NdrStatusMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>   
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...filter("delivery_type_id")} className="form-control-sm form-control">
                            <option value="">Delivery Type</option>
                            {Object.entries(delivery_type_id).map(([key,value]) => (
                            <option value={key}>{value}</option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col> 
					
					 
					 <Col sm={6} md={5} lg={5} xl={4}>
					 <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
						<DateRangePicker
						initialSettings={ranges}
						onCallback={handleCallbacks}
						>
						<div className='form-control cursor-pointer d-flex align-items-center'>
						{moment(datestates.start).format("DD-MM-YYYY")} to {moment(datestates.end).format("DD-MM-YYYY")}
						</div>
						</DateRangePicker>
                      
					</FormGroup>
					</Col>
					
                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" onClick={AllNdrList} outline>Reset</Button>
                    </Col>
                  </Row>
                </Form>
            </Collapse>
              <CardBody className="p-0">
              {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                <>
                    <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                      <div className="showing-count">
                    Showing {currentCount} to {(currentCount-1)+(Object.entries(liveNdrList.data).length)} of {liveNdrList.TotalRows} entries
                    </div>
                      {liveNdrList.TotalRows > dropFilterCount ?
                    <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                            <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </Input>
                    </FormGroup>
                  </Form>
                  :''}
                  </div>
                  </>
                :''}
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th>NDR Date</th>
                      <th>SHIPMENT</th>
                      <th>PRODUCT</th>
                      <th>CUSTOMER</th>
                      <th>STATUS</th>
              
                    </tr>
                   </thead>
                   <tbody>
                  {checkData(liveNdrList.data) ? liveNdrList.data.map((item, index) => (

                    <tr>
                       <td className="align-middle"><span>{changeIntoDate(item.created_at)}</span></td>
                       <td className="align-middle text-left col-2">
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{(item.CourierName)}</p>                             
                            </div>
                            <div className="d-flex"><span className="mb-1 d-block text-left text-nowrap text-primary">AWB - {item.awb_no}</span>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn d-none" id={`copy${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <div className="text-nowrap">Order no. -<Link to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></div>
                             </div>
                        </div>
                      </td>
              
                      <td className="align-middle">
                        <div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                        <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                          {item.product_name}
                        </UncontrolledTooltip>
                        <br />{'Order On - ' + changeIntoDate(item.order_date)}
                      </td>
               
                      <td className="align-middle">{item.customer_name}<br /> {item.customer_mobile}</td>
                      {/* <td className="align-middle">{item.awb_no}<br /> {item.product_cod_value}</td> */}
                
                      <td className="align-middle"><span>{order_ndrs[item.ndr_status_id]}</span></td>
                
                      <td className="align-middle">
                        {item.merchant_remarks}
                      </td>
                    </tr>
                    )):
                      loadertable ? <tr><td colSpan="6" className="text-center">Loading...</td></tr>
                    :<tr><td colSpan="6" className="text-center">No Data Found</td></tr>}
                  </tbody>
                </Table>
                {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                <>
                {liveNdrList.TotalRows > dropFilterCount ?
            <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
              <div className="showing-count">
              Showing {currentCount} to {(currentCount-1)+(Object.entries(liveNdrList.data).length)} of {liveNdrList.TotalRows} entries
              </div>
              <Pagination aria-label="Page navigation example">
                  <PaginationItem>
                  <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink> 
                  </PaginationItem>
                  <PaginationItem>
                  <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                  </PaginationItem>
              </Pagination>
            </div>
            :''}
            </>
            :''}
              </CardBody>
			 </div> 
        </Card>
      </Col>
      </Row>
  );
}

function mapDispatchToProps(state){
  return {
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(AllNdr);