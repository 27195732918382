import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody,Button, CardTitle,Collapse, FormGroup,UncontrolledTooltip, InputGroup,InputGroupAddon,InputGroupText,Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner,Badge, Label, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,faChevronDown, faArrowUp, faChevronRight,faFilter } from '@fortawesome/free-solid-svg-icons';
import { delivery_type_id, order_ndrs, ndr_actions, download_delay } from '../../config'; 
import { connect } from 'react-redux';
import { checkData,checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2';
import { changeIntoDateYmd, copyToClipBoard, moreText, changeIntoDate } from '../../utlis';
import { toast,ToastContainer } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


const LiveNdr = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [liveNdrList, setLiveNdrList] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage,setSelectperpage] = useState(20);
  const [currentCount,setCurrentCount] = useState(1);
  const [isExport, setIsExport] = useState(false);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [modalTakeAction, setModalTakeAction] = useState(false);
  const [modalUploadNDR, setModalUploadNDR] = useState(false);
  const [filterDate, setFilterDate] = useState(new Date());
  const [excelFile, setExcelFile] = useState();
  const [ndrId, setNdrId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [actionDate, setActionDate] = useState(new Date());
  const dropFilterCount = 20;
  const history = useHistory();
  const [datestate, setDateState] = useState({ start: moment().subtract(29, 'days'), end: moment(), });
   
  const { start, end } = datestate;
  
  const toggleTakeAction = (id) => {
    setModalTakeAction(true);
    setNdrId(id)
  } 
  const toggleTakeActionClose = (id) => {
    setModalTakeAction(false);
    setNdrId('')
  } 
  const toggleUploadNDR = () => {
    setModalUploadNDR(!modalUploadNDR);
  } 
  
  const handleCallback = (start, end) => {
    setDateState({ start, end });
  };
  
// --------------------------------------------------------------

const ranges = {
    startDate: start.toDate(),
    endDate: end.toDate(),
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ],
      'Last 7 Days': [
        moment().subtract(6, 'days').toDate(),
        moment().toDate(),
      ],
      'Last 30 Days': [
        moment().subtract(29, 'days').toDate(),
        moment().toDate(),
      ],
      'This Month': [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ],
    },
  }





//---------------------------------------------------------------
 

  const [ndrStatus, setNdrStatus] = useState([]);
  const NdrStatusMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "NDR Status"
  }
  const opt_ndrStatus = [];
  for (const property in order_ndrs) {
    let tempObj = {label: `${order_ndrs[property]}`, value: property };
    opt_ndrStatus.push(tempObj);
  }

  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings={
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if(checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)){
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function(key){
      let tempObj = {label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }     
      return opt_allCourier.push(tempObj)
    });
  }

  const AllNdrList = () => {
  let data = {"is_live":1}
  // let data = {}
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id=props.user[0].id
    }else{
      data.merchant_id=props.user[0].id
    }
  setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${page}&per_page=${selectperpage}`,
      data: data,
    }).then((res) => {
      setLoadertable(false)
        if(res.data.error === false){
          setLiveNdrList(res.data);
        }else{
          Swal.fire({icon:'error',text:res.data.msg,title:'Error'});
        }
    }).catch((err) => {
      setLoadertable(false)
      Swal.fire({icon:'error',text:err,title:'Error'});
    });
  }
  useEffect(() => {
    AllNdrList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
// copy text
const copied = (text) =>{
  let decision = copyToClipBoard(text);
  decision.then(function(result) {
    if(result === true){
      toast("Copied!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  })
}
function handleSelectChange(event) {
  setSelectperpage(event.target.value);
  setLoadertable(true)
  setPage(1)
  setCurrentCount(1)
  let pagePerpage = {"page":1, "per_page":event.target.value};
  onpaginationFilter(filterInput,pagePerpage)
}
function nextPageData() {
if(checkData(liveNdrList.data)&&checkUndeNullBlank(liveNdrList.totalPage)){
  // &&checkUndeNullBlank(liveNdrList.totalPage).length>page.length
    if(liveNdrList.totalPage > page){
      setCurrentCount(currentCount+Object.entries(liveNdrList.data).length)
  setPage(page+1)
  setLoadertable(true)
    let pagePerpage = {"page":page+1, "per_page":selectperpage};
    onpaginationFilter(filterInput,pagePerpage);
  }
}
}
function previousPageData() {
if(page >1){
setPage(page-1)
checkData(liveNdrList.data)
if(currentCount>Object.entries(liveNdrList.data).length){
  setCurrentCount(currentCount-selectperpage)
}else{
  setCurrentCount(1)
}
let pagePerpage = {"page":page >1 && page-1, "per_page":selectperpage};
  onpaginationFilter(filterInput,pagePerpage);
}
}

const { register:filter, handleSubmit:filterliveNdrListSubmit } = useForm();
const onpaginationFilter = (data,pagePerpage) => {
  onCommonFilter(data,pagePerpage)
};
const onFilterliveNdrList = (data,pagePerpage) => {
  setIsExport(true);
  pagePerpage.page=1
  if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(ndrStatus) || checkData(allCourier) || checkUndeNullBlank(datestate.start) ){
  setCurrentCount(1);
  setSelectperpage(selectperpage);
  setFilterInput(data);
  onCommonFilter(data,pagePerpage)
}
};
const onCommonFilter = (data,pagePerpage) => {
  let byData = {}
  let tmpNdrStatus = [];
  let courierId = [];
  if(checkData(ndrStatus)){
    ndrStatus.map((item) => (
      tmpNdrStatus.push(item.value)
    )); 
  } 
  if(checkData(allCourier)){
    allCourier.map((item) => (
      courierId.push(item.value)
    )); 
  } 
  

  if(checkUndeNullBlank(courierId)){
    byData.courier_id=courierId
  }
  setLoader(true)
  if(data === undefined){
    data = filterInput
  }
/* setDateState({ start: moment().subtract(29, 'days'), end: moment(), })
let dateStart = moment().subtract(29, 'days').format("YYYY-MM-DD");
let dateEnd = moment().format("YYYY-MM-DD"); */
  
   let ds =  moment(start).format("YYYY-MM-DD") ;
   let de =  moment(end).format("YYYY-MM-DD") ;
   

  byData={"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?data.search_value:'',"ndr_status":checkUndeNullBlank(tmpNdrStatus)?tmpNdrStatus:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"courier_id":checkUndeNullBlank(courierId)?courierId:'',"start_date": ds, "end_date": de }
  setFilterInput(byData);
  if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
    byData.admin_id = props.user[0].id
  }else{
    byData.merchant_id = props.user[0].id
  }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_NDR}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)?pagePerpage.page:page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page)?pagePerpage.per_page:selectperpage}`,
      data: byData,
    }).then((res) => {
      setLoader(false);
      if(res.data !== undefined && res.data.fatal !== true){
        if(parseInt(res.data.errno)>0){
          if(res.data.sqlMessage!==undefined){
            Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
          }
        }else{
          if(res.fatal !== true && res.error !== true){
            setLiveNdrList(res.data);
          }
          else{
            Swal.fire({title: 'Error',text: res.msg,icon: 'error',})
          }
        }
      }
    }).catch((err) => {
      Swal.fire({icon:'error',text:err,title:'Error'});
      setLoader(false);
    });
};
const exportNdr = () => {
    let data = filterInput
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      data.admin_id = props.user[0].id
    }else{
      data.merchant_id = props.user[0].id
    }
    data.is_export=1
    data.is_live=1
    
      setLoader(true);
      axios.post(process.env.REACT_APP_ALL_NDR, data)
  .then((res) => {
    setLoader(false)
    if(res.data !== undefined && res.data.fatal !== true && res.data !== true){
      if(parseInt(res.data.errno)>0){
        if(res.data.sqlMessage!==undefined){
          Swal.fire({icon:'error',text:res.data.sqlMessage,title:'Error in SQL'});
        }
      }else{
        if(res.data.is_export === 1){
          setTimeout(function(){ 
            setLoader(false);
            history.push("/my-downloads"); 
          },download_delay);
        }
      }
    }
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
}
const { register:takeaction, handleSubmit:handleTakeAction, formState: { errors }, reset:resetAction} = useForm();
const submitTakeAction = (data) => {
  data.ndr_id = ndrId
  data.reattempt_date = changeIntoDateYmd(actionDate)
  // data.reattempt_date = changeIntoDateYmd(filterDate)
  axios.post(process.env.REACT_APP_SINGLE_NDR_UPDATE, data)
  .then((res) => {
    setLoader(false)
        if(res.data.error === false){
          Swal.fire({icon:'success',text:res.data.msg,title:'Success'}).then(okay => {
            if (okay) {
              setNdrId('');
              AllNdrList();
              setModalTakeAction(false);
              resetAction();
           }
         });
        }
  }).catch((err) => {
    setLoader(false)
    Swal.fire({icon:'error',text:err,title:'Error'});
  });
}
const { register:uploadndr, handleSubmit:handleUploadNDR, formState: { errors: errors2 }} = useForm();
const submitUploadNDR = (e)=>{
  setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);  
    if(props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin"){
      formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');  
    }else{
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');  
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID); 
    } 
    axios
      .post(`${process.env.REACT_APP_MULTIPLE_NDR_UPLOAD}`, formData)
      .then((res) => {
          let errorFile='';
          if(res.data.errorfile !== undefined){
           errorFile = process.env.REACT_APP_BASE_URL+res.data.errorfile;
          }
          setLoader(false);
          setExcelFile();
          setModalUploadNDR(false);
          if(res.data.error === false){
          Swal.fire({
            title: 'Success',text: res.data.msg,icon: 'success',allowOutsideClick: false,allowEscapeKey: false,
            html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`:''}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              AllNdrList();
           }
         });
        }else{
           Swal.fire({title: 'Error',text: res.data.msg,icon: 'error',allowOutsideClick: false,allowEscapeKey: false,
           html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          }).then(okay => {
            if (okay) {
              AllNdrList();
           }
         });
        }
      }).catch((err) => {
        setLoader(false)
        Swal.fire({icon:'error',text:err,title:'Error'});
      });
}

const handleFileInput = (e) => {
  setExcelFile(e.target.files[0]);
}

const onFilter = () => setIsOpen(!isOpen);
const handleChangeS = event => {
  event.preventDefault();
  event.stopPropagation();
  setFilterDate({ inputStart: event.target.value });
};
const  handleEvent = (event, picker) => {
  console.log(event);
  setFilterDate({
    inputStart: picker.startDate.format("DD/MM/YYYY"),
    inputFinish: picker.endDate.format("DD/MM/YYYY")
  });
}
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
        <ToastContainer className="copied-con" />
      <Row>
       <Col sm={12}>
        <Card className="p-0 my-3 master-content content-wrapper border-0">
		<div className="page-header content-header">
		
		<div className="card-title title_icon">
		<i className="bi bi-card-heading"></i> 
		Manage NDR
	 
		<div className="otherlinks">
			<div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
			<div className="btn-text btn-light" onClick={exportNdr}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Export Pending NDR</span>
			</div>
			<div className="btn-text btn-light" onClick={toggleUploadNDR}><span className="icon mx-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Pending Instructions</span>
			</div>
			<div className="btn-text btn-light mx-2" onClick={onFilter}><span className="icon mr-2"><FontAwesomeIcon icon={faFilter} /></span><span>Filter</span></div>
		  </div>
		</div>
	</div>
	 
            <Collapse isOpen={isOpen}>

            <Form onSubmit={filterliveNdrListSubmit(onFilterliveNdrList)} className="filter-form">
                  <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-center">
                    <Col sm={6} md={5} lg={5} xl={4}>
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => {if(e.target.selectedOptions[0].text !== "Search By"){setSearchValue("Enter "+e.target.selectedOptions[0].text)}else{setSearchValue('')}}}>
                              <option value=''>Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value= "customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                            </InputGroupText>
                          </InputGroupAddon>
                          <input {...filter("search_value")} type="text"  className="form-control-sm form-control" placeholder={searchValue} />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Col sm={6} md={3} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_ndrStatus}
                              value={ndrStatus}
                              onChange={setNdrStatus}
                              labelledBy="NDR Status"
                              overrideStrings={NdrStatusMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>   
                    <Col sm={6} md={4} lg={3} xl={2}>
                        <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect 
                              options={opt_allCourier}
                              value={allCourier}
                              onChange={setAllCourier}
                              labelledBy="All Couriers"
                              overrideStrings={allCourierMultiSelectSettings}
                            />
                          </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...filter("delivery_type_id")} className="form-control-sm form-control">
                            <option value="">Delivery Type</option>
                            {Object.entries(delivery_type_id).map(([key,value]) => (
                            <option value={key}>{value}</option>
                            ))}
                          </select>
                      </FormGroup>
                    </Col>
					</Row>
					
					<Row>
					 <Col sm={6} md={5} lg={5} xl={4}>
					 <FormGroup>
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
						<DateRangePicker
						initialSettings={ranges}
						onCallback={handleCallback}
						>
						<div className='form-control cursor-pointer d-flex align-items-center'>
						{moment(datestate.start).format("DD-MM-YYYY")} to {moment(datestate.end).format("DD-MM-YYYY")}
						</div>
						</DateRangePicker>
                      
					</FormGroup>
					</Col>

                    <Col sm={3} className="filter-btn">
                      <Button className="btn btn-sm" color="primary">Search</Button>
                      <Button type="reset" className="btn  btn-sm mx-2" color="primary" onClick={AllNdrList} outline>Reset</Button>
                    </Col>
                  </Row>
              </Form>
              </Collapse>
              <CardBody className="p-0">
              <div className="showing-count">
                  {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                  <span className="small">Showing {currentCount} to {(currentCount-1)+(Object.entries(liveNdrList.data).length)} of {liveNdrList.TotalRows}</span>: ''}  
                </div>
                <Table responsive borderless className="text-center customTable">
                  <thead>
                    <tr>
                      <th>NDR Date</th>
                      <th>SHIPMENT</th>
                      <th>PRODUCT</th>
                      <th>CUSTOMER</th>
                      <th>ACTION</th>
                      <th>STATUS</th>
                      
                    </tr>
                   </thead>
                  <tbody>
                  {checkData(liveNdrList.data) ? liveNdrList.data.map((item, index) => (

                    <tr>
                       <td className="align-middle"><span>{changeIntoDate(item.created_at)}</span></td>
                       <td className="align-middle text-left col-2">
                        <div className="list-group-item border-0 p-0 d-inline-flex">
                          <div className="list-content text-left">
                            <div className="">
                              <p className="mb-0">{(item.CourierName)}</p>                             
                            </div>
                            <div className="d-flex"><span className="mb-1 d-block text-left text-nowrap text-primary">AWB - {item.awb_no}</span>
                              <span onClick={() => copied(item.awb_no)} className="ml-1 copy-btn d-none" id={`copy${item.id}`}>
                                <Copy />
                                <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                  Copy
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <div className="text-nowrap">Order no. -<Link to={`/order-detail/${item.order_id}`}>{item.order_no}</Link></div>
                             </div>
                        </div>
                      </td>
              
                      <td className="align-middle">
                        <div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                        <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                          {item.product_name}
                        </UncontrolledTooltip>
                        <br />{'Order On - ' + changeIntoDate(item.order_date)}
                      </td>
               
                      <td className="align-middle">{item.customer_name}<br /> {item.customer_mobile}</td>
                      {/* <td className="align-middle">{item.awb_no}<br /> {item.product_cod_value}</td> */}
                
                      <td className="align-middle">
                        {item.merchant_action_id === null && 
                        <Badge color='primary' className="custom-shadow py-2 d-flex justify-content-center mb-2 cursor-pointer" onClick={() => toggleTakeAction(item.id)} style={{maxWidth:"197px"}}>Take Action</Badge>}
                        <span className="d-none custom-shadow bg-white mb-2 d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-success">REATTEMPT</span><span className="font-weight-normal text-secondary">{item.merchant_action_id === 1 ? changeIntoDate(item.merchant_action_reattempt_date): "NA"}</span></span>
                        <span className="d-none custom-shadow bg-white d-flex cursor-pointer" style={{maxWidth:"197px"}}><span className="mr-2 text-danger">RTO</span><span className="font-weight-normal text-secondary">{item.merchant_action_id === 2 ? changeIntoDate(item.merchant_action_reattempt_date): "NA"}</span></span>
                      </td>
                      <td className="align-middle"><span>{order_ndrs[item.ndr_status_id]}</span></td>
                
                      <td className="align-middle">
                        {item.merchant_remarks}
                      </td>
                    </tr>
                    )):
                      loadertable ? <tr><td colSpan="6" className="text-center">Loading...</td></tr>
                    :<tr><td colSpan="6" className="text-center">No Data Found</td></tr>}
                  </tbody>
                </Table>
                {checkData(liveNdrList) && checkData(liveNdrList.data) && checkUndeNullBlank(liveNdrList.TotalRows) ?
                <>
                {liveNdrList.TotalRows > dropFilterCount ?
               <Row className="m-0 table-footer">
               <Col xs="6">               
                <Pagination aria-label="Page navigation example align-items-center">
                    <PaginationItem className="prev">
                      <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink> 
                    </PaginationItem>
                    <PaginationItem className="next ml-3">
                      <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                    </PaginationItem>
                </Pagination>
                </Col>
                <Col xs="6">
                <Form>
                  <FormGroup>
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                          <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          </select>
                  </FormGroup>
                </Form>
                </Col>
               </Row>:''}
               </>
               :''}
              </CardBody>
              <Modal isOpen={modalTakeAction} toggle={toggleTakeAction} size="md" scrollable={true}>
                <ModalHeader toggle={toggleTakeActionClose}>Take Action</ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleTakeAction(submitTakeAction)}>
                    <Row className="mx-0 justify-content-center downloadModal">
                      <Col sm={6}>
                          <FormGroup>
                            <Label for="action_id">Select Action<span className="text-danger">*</span></Label>
                            <div className="position-relative">
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select {...takeaction("ndr_action_id", {required : 'Please Select Action'})} id="action_id" className="form-control-sm form-control">
                                <option value="">Select Action</option>
                                {Object.entries(ndr_actions).map(([key,value]) => (
                              <option value={key}>{value}</option>
                              ))}
                              </select>
                            </div>
                            {errors.ndr_action_id && <span className="text-danger d-block error">{errors.ndr_action_id.message}</span>}
                          </FormGroup>
                      </Col>
                      <Col sm={6}>
                          <FormGroup>
                            <Label for="reattempt_date">Reattempt Date<span className="text-danger">*</span></Label>
                            {/* <DatePicker value={filterDate} onChange={date => setFilterDate(date)} format="DD-MM-YYYY" change={onDateChange}  /> */}
                            {/* <DateRangePicker
                              // autoApply={true}
                            autoUpdateInput={false}
                            startDate={filterDate.inputStart}
                            endDate={filterDate.inputFinish}
                            locale={{ format: "DD/MM/YYYY" }}
                            onApply={handleEvent}
                            autoApply={true}
                            singleDatePicker={true}
                          >
                            <input
                              type="text"
                              value={filterDate.inputStart}
                              onChange={handleChangeS}
                            />
                          </DateRangePicker> */}
                                    <DatePicker
                                      selected={actionDate}
                                      onChange={date => setActionDate(date)}
                                    />
                          </FormGroup>
                      </Col>
                      <Col sm={12}>
                          <FormGroup>
                            <Label for="reattempt_date">Remarks<span className="text-danger">*</span></Label>
                              <textarea  {...takeaction("ndr_remarks", {required : 'Please Enter Remarks' })}  rows="4" id="remarks" placeholder="Input Remarks" className="form-control-sm form-control" />
                              {errors.ndr_remarks && <span className="text-danger d-block error">{errors.ndr_remarks.message}</span>}
                          </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>              
              <Modal isOpen={modalUploadNDR} toggle={toggleUploadNDR} scrollable={true} >
                <ModalHeader toggle={toggleUploadNDR}>Upload Your NDR Actions</ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleUploadNDR(submitUploadNDR)}>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your NDR via XLSX</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Export NDR and use same template</p>
                  <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                   <Row className="m-0 pb-1">
                     <Col sm={12} className="p-0">
                        <p className="mb-2"><span>Export NDR and Upload in same Format File</span></p>
                     </Col>
                     <Col sm={12} className="m-auto pt-1 p-0">
                      <FormGroup>
                        <input {...uploadndr("excelUpload",{ 
                              required:'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                  files[0]?.type
                                ) || 'Only .xslx file allowed',
                            },})} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input"  onChange={handleFileInput} />
                        <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name:'Choose file' }</Label>
                        {errors2.excelUpload && <span className="text-danger d-block error">{errors2.excelUpload.message}</span>}
                      </FormGroup>
                     </Col>
                     <Col sm={12} className="text-right">
                        <Button className="btn btn-sm" color="primary">Upload</Button>
                      </Col>
                  </Row>
                  </Form>  
                </ModalBody>
              </Modal>
			  
			  </div>
            </Card>
         
      </Col>
      
      </Row>
       </div>
  );
}

function mapDispatchToProps(state){
  return{
    state:state.DataReducer
  }
}

export default connect(mapDispatchToProps)(LiveNdr);