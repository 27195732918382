import { VIEW_STATE, VIEW_CITY, MERCHANT_LOGIN, MERCHANT_SIGNUP, FORGOT_PASSWORD, OTP_PASSWORD, RESET_PASSWORD,VIEW_COURIER_OVER, ADMIN_LOGIN,VIEW_CITY_STATE, VIEW_PINCODE, VIEW_COURIER, VIEW_MERCHANT, VIEW_MERCHANT_REMMITANCE, VIEW_MERCHANT_COURIER,SIGNUP_MERCHANT, VIEW_CHANNEL_BRAND,VIEW_FREIGHTOVER,VIEW_CARDOVER,VIEW_ORDERSUMMARY,VIEW_M_PERFORMANCEOVERVIEW,VIEW_M_SHIPMENTSTAUS, VIEW_REMITTANCEOVER, WALLET_BALANCE,VIEW_COURIER_REMMITANCE,VIEW_DAILY_SHIP_SUMMARY, VIEW_SHIPMENT_SUMMARY,VIEW_DASH_COURIER_REMITTANCE,VIEW_DASH_FREIGHT_REMITTANCE,VIEW_DASH_PENDING_SHIPMENT, VIEW_DASH_PICKUP_PENDING,VIEW_DASH_NOT_ATTEMPTED, VIEW_MERGE_ACCOUNT,VIEW_MERCHANT_EASYECOM_MARKETPLACES,VIEW_MERCHANT_EASYECOM_MARKETPLACES_LIST,VIEW_MERCHANT_EASYECOM_SETTINGS,VIEW_MERCHANT_EASYECOM_ADDUSR,VIEW_MERCHANT_EASYECOM_UPDUSR,VIEW_MERCHANT_STANDARD_PRICING,VIEW_MERCHANT_REMAINING_REMMITANCE } from "./Types";

import AllApi from "../services/AllApi";

export const AllState = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetState(postParams,getParams);
        dispatch({
            type: VIEW_STATE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllCity = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCity(postParams,getParams);
        dispatch({
            type: VIEW_CITY,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllPincode = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetPincode(postParams,getParams);
        dispatch({
            type: VIEW_PINCODE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllCourier = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCourier(postParams,getParams);
        dispatch({
            type: VIEW_COURIER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllMerchant = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetMerchant(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllSignupMerchant = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetSignupMerchant(postParams,getParams);
        dispatch({
            type: SIGNUP_MERCHANT,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const MerchantRemittanceList = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetMerchantRemittance(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_REMMITANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const CourierRemittanceList = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCourierRemittance(postParams,getParams);
        dispatch({
            type: VIEW_COURIER_REMMITANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllShipmentMerchantCourier = (postParams,getParams) => async (dispatch) => {
     // commented as on Oct09,2023 //
	try {
        const res = await AllApi.GetShipmentMerchantCourier(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_COURIER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    } 
	
}
export const TotalWalletBalance = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetWalletBalance(postParams,getParams);
        dispatch({
            type: WALLET_BALANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const AllChannelBrand = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetChannelBrand(postParams,getParams);
        dispatch({
            type: VIEW_CHANNEL_BRAND,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const LoginMerchant = (username,password) => async (dispatch) => {
    try {
        const res = await AllApi.MerchantLogin(username,password);
        dispatch({
            type: MERCHANT_LOGIN,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}

export const SignupMerchant = (data) => async (dispatch) => {
    try {
        const res = await AllApi.MerchantSignup(data);
        dispatch({
            type: MERCHANT_SIGNUP,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const PasswordForgot = (data) => async (dispatch) => {
    try {
        const res = await AllApi.MerchantForgotPassword(data);
        dispatch({
            type: FORGOT_PASSWORD,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const MerchantOtpPassword = (data) => async (dispatch) => {
    try {
        const res = await AllApi.MerchantOtpPassword(data);
        dispatch({
            type: OTP_PASSWORD,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const ResetMerchantPassword = (data) => async (dispatch) => {
    try {
        const res = await AllApi.MerchantResetPassword(data);
        dispatch({
            type: RESET_PASSWORD,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const LoginAdmin = (data) => async (dispatch) => {
    try {
        const res = await AllApi.AdminLogin(data);
        dispatch({
            type: ADMIN_LOGIN,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dailyShipSummary = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDailyShipSummary(postParams,getParams);
        dispatch({
            type: VIEW_DAILY_SHIP_SUMMARY,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const shipmentSummary = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetShipmentSummary(postParams,getParams);
        dispatch({
            type: VIEW_SHIPMENT_SUMMARY,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashCourierRmittance = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDashCourierRmittance(postParams,getParams);
        dispatch({
            type: VIEW_DASH_COURIER_REMITTANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashFreightRmittance = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDashFreightRmittance(postParams,getParams);
        dispatch({
            type: VIEW_DASH_FREIGHT_REMITTANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashPendingShipment = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDashPendingSummary(postParams,getParams);
        dispatch({
            type: VIEW_DASH_PENDING_SHIPMENT,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashPickupPending = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDashPickupPending(postParams,getParams);
        dispatch({
            type: VIEW_DASH_PICKUP_PENDING,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashDashNotAttempted = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetDashNotAttempted(postParams,getParams);
        dispatch({
            type: VIEW_DASH_NOT_ATTEMPTED,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashFreightOverView = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetFrieghtOver(postParams,getParams);
        dispatch({
            type: VIEW_FREIGHTOVER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashCardOverView = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCardOver(postParams,getParams);
        dispatch({
            type: VIEW_CARDOVER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashUploadSummary = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetOrderSummary(postParams,getParams);
        dispatch({
            type: VIEW_ORDERSUMMARY,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const performanceOverviewM = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetPerformanceOverM(postParams,getParams);
        dispatch({
            type: VIEW_M_PERFORMANCEOVERVIEW,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const shipmentStatusM = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetShipmentStatusM(postParams,getParams);
        dispatch({
            type: VIEW_M_SHIPMENTSTAUS,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const citysStates = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCityState(postParams,getParams);
        dispatch({
            type: VIEW_CITY_STATE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const courierOverV = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetCourierOver(postParams,getParams);
        dispatch({
            type: VIEW_COURIER_OVER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const dashRemittanceOverView = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetRemittanceOver(postParams,getParams);
        dispatch({
            type: VIEW_REMITTANCEOVER,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const mergeAccountList = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetMergeAccountList(postParams,getParams);
        dispatch({
            type: VIEW_MERGE_ACCOUNT,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
export const resetReducer = () => {
    return {
      type: "RESET"
    }
  }

export const MerchantEasyEcomMarketplaceList = (postParams,getParams) => async (dispatch) => {
    try {
        const res = await AllApi.GetMarchantEasyecomMarketplaces(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_EASYECOM_MARKETPLACES,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}  

export const AllEasyEcomMarketplaceList = (postParams,getParams) => async (dispatch) => {
	
    try {
        const res = await AllApi.GetAllEasyecomMarketplaces(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_EASYECOM_MARKETPLACES_LIST,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}  

export const AllEasyEcomSettings = (postParams,getParams)  => async (dispatch) => {
	try {
        const res = await AllApi.GetEasyEcomSettings(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_EASYECOM_SETTINGS,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}	

export const EasyEcomaddnew = (postParams,getParams)  => async (dispatch) => {
	try {
        const res = await AllApi.GetEasyEcomaddusr(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_EASYECOM_ADDUSR,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}

export const EasyEcomupdusr = (postParams,getParams)  => async (dispatch) => {
	try {
        const res = await AllApi.GetEasyEcomupdusr(postParams,getParams);
        dispatch({
            type: VIEW_MERCHANT_EASYECOM_UPDUSR,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}


export const StdmerchantPricing = (postParams,getParams)  => async (dispatch) => {
	try {
        const res = await AllApi.GetStdmerchantPricing(postParams,getParams);
        dispatch({  
            type: VIEW_MERCHANT_STANDARD_PRICING,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}

export const AllRemainingRemmitance = (postParams,getParams)  => async (dispatch) => {
	try {
        const res = await AllApi.GetRemainingRemmitance(postParams,getParams);
        dispatch({  
            type: VIEW_MERCHANT_REMAINING_REMMITANCE,
            payload: res.data
        });
        return Promise.resolve(res.data)
    } catch(err) {
        return Promise.reject(err);
    }
}
