import React, {useState, useEffect} from 'react';
import { Nav, NavItem , NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown, faBalanceScale, faBars, faBook, faCodeBranch, faCube, faLink, faLocationArrow, faShoppingBag, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../../assets/images/logo.png";
import { useLocation } from 'react-router';
 

const Sidebar = (props) =>  {
    const urlPath = useLocation();
	
	const [showmenu, setshowmenu] = useState(false);
    const toggleshowmenu = () => setshowmenu(!showmenu);
	 
	
    return (
        <div className="teckieMain techiemerchant">
            
            <div className="teckieMain-scroll mm-active ps ps--active-y">
            <Nav vertical>
			  <NavItem className="nav-toggle">
                  <div className="dashboard_bar mr-3" onClick={() => props.sideNavToggle()}>
                    <i className="bi bi-list"></i> 
					
                  </div>
              </NavItem>
			  
                <NavItem>
                    <Link to='/' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faTachometerAlt} /></span>
                        <span className="navContent">Dashboard</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='/allshipments' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/allshipments' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faCube} /></span>
                        <span className="navContent">Manage Shipment</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='/all-orders' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/all-orders' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faShoppingBag} /></span>
                        <span className="navContent">Manage Orders</span>
                    </Link>
                </NavItem>
                
				
			   
              <NavItem>
			     <Link to="#" onClick={toggleshowmenu} className="d-flex align-items-center nav-link"> Channel </Link>
					{ showmenu && 
					<div className="sub-sidenav">
					<ul className="subnav_menu1"> 
					<li className="subnav_menu_item1">  
					<Link to='/channel-orders?o=all' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/channel-orders' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faShoppingBag} /></span>
                        <span className="navContent">Channel Orders</span>
                    </Link>
                     </li> 
					 <li className="subnav_menu_item2">
					   <Link to='/integration-channel' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/integration-channel' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faLink} /></span>
                        <span className="navContent">Channel Integration</span>
                    </Link>
					 </li>
					 </ul>
					</div>
					}
              </NavItem> 
              
				
                <NavItem>
                    <Link to='/rto' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/rto' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faArrowCircleDown} /></span>
                        <span className="navContent">Manage RTO</span>
                    </Link>
                </NavItem>
				 
                <NavItem>
                    <Link to='/ndr' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/ndr' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faBook} /></span>
                        <span className="navContent">Non-Delivery Report</span>
                    </Link>
                </NavItem>
                <NavItem className='' >
                <Link to='/weight-mismatch' className={`d-flex align-items-center nav-link ${urlPath.pathname === '/weight-mismatch' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faBalanceScale} /></span>
                        <span className="navContent">Weight Disputes</span>
                    </Link>
                </NavItem>
                <NavItem>
                    <Link to='/account' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/account' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faUser} /></span>
                        <span className="navContent">Account</span>
                    </Link>
                </NavItem>

                <NavItem className={   `${(props.user[0].id === 152 || props.user[0].id === 153) && 'd-none' }` }>
                    <Link to='/my-pickuppoint' className={`d-flex align-items-center nav-link ${urlPath.pathname ==='/my-pickuppoint' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faLocationArrow} /></span>
                        <span className="navContent">Manage Hubs</span>
                    </Link>
                </NavItem>
                <NavItem className={   `${(props.user[0].id === 152 || props.user[0].id === 153) && 'd-none' }` }>
                    <Link to='/brands' className={ `d-flex align-items-center nav-link  ${urlPath.pathname ==='/brands' && 'active'}`}>
                        <span className="navIcon"><FontAwesomeIcon icon={faCodeBranch} /></span>
                        <span className="navContent">Stores</span>
                    </Link>
                </NavItem>

            </Nav>
            </div>
        </div>
    )
}

export default Sidebar
