import React, {useState, useRef} from 'react';
import reactdom from 'react-dom';

import { Row, Col, Button, FormGroup,Form, Label} from 'reactstrap';
import { useForm } from "react-hook-form";
// import axios from 'axios';
import axios from '../../CommonAxios';
import { easyecom_statecodes } from '../../config';

import Swal from 'sweetalert2';
import { checkData } from '../../ObjectExist';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faTimes, faArrowsAltH, faChevronDown, faPlus, faChevronRight, faDownload, faCartPlus, faChevronLeft, faFilter } from '@fortawesome/free-solid-svg-icons';

const AddMerchant = (props) => {
  const [logo, setLogo] = useState();

  const handleFileInput = (e) => {
    setLogo(e.target.files[0])
}
 


  const { register:addMerchant, handleSubmit:MerchantSubmit,watch:watchAddpswd, formState: { errors } } = useForm();
 
  const password = useRef({});
  password.current = watchAddpswd("password", "");
  
  const [ecomform, setEcomForm] = useState();
  const [ecomform2, setEcomForm2] = useState();
   const [ecomform3, setEcomForm3] = useState();
 
	const [selectedOption, setSelectedOption] = useState('');

	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [checked3, setChecked3] = useState(false) ;
	
    const [eusername, setEusername] = useState('') ;
	const [epassword, setEpassword] = useState('') ;
	const [eapikey, setEapikey] 	= useState('') ;
	const [elocation, setElocation] = useState('') ;
	
	const handleUsername = (e) => {
	setEusername(e.target.value) ;
	}
	
	const handlePassword = (e) => {
	 setEpassword(e.target.value) ;
	}
	const handleApikey = (e) => {
	setEapikey(e.target.value) ;
	}
	const handleLocation = (e) => {
	setElocation(e.target.value) ;
	}
	
	
    const handleChange3 = () => {
		setChecked3(!checked3);
	}
 
	const handleOptionChange1 = () => {
		setChecked1(!checked1) ;
		setEcomForm(!ecomform) ;
	}
	
	const handleOptionChange2 = () => {
		setChecked2(!checked2);
		setEcomForm2(!ecomform2) ;
	}
	
	const handleOptionChange3 = () => {
		 setChecked3(!checked3) ;
		// setEcomForm3(!ecomform3) ;
	}
	
	const handleReset = () => {
		setChecked2('');
		setEcomForm2('');
		setChecked1('');
		setEcomForm('') ;	
	}
	
	const checkother1 = () => {
		if(checked2) {
		 setChecked2('');
		 setEcomForm2('');
		}
	}
	
	
	 const checkother3 = () => {
		 setChecked3(!checked3);
		 //if(checked3) {
		 //setChecked3('');
		 // setEcomForm3('');
		// }
	} 
	
	const checkother2 = () => {
		if(checked1) {
			setChecked1('');
			setEcomForm('') ; 
			setEusername('') ;
			setEpassword('') ;
			setEapikey('') ;
			setElocation('') ; 
		} 
	}
	 
	
  
  // const set_create_easyecom_acount =  create_easyecom_account
  
  const onAddMerchantSubmit = (data) => {
	   
	let create_easy_account =  0 ;
 
    let formData = new FormData();
    formData.append("admin_id", checkData(props.user[0]) ? props.user[0].id : '');
    formData.append("company_name", data.company_name);
    formData.append("brand_name", data.brand_name);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("billing", data.billing);
    formData.append("freight_type", data.freight_type);
    formData.append("status", data.status);
    formData.append("mobile", data.mobile);
    formData.append("wallet_balance", data.wallet_balance);
    formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
    formData.append("max_cod_value_per_order", data.max_cod_value_per_order);
    formData.append("remittance_tat_period", data.remittance_tat_period);
    formData.append("refer_by", data.refer_by);
    formData.append("logo", logo);
	
    let easy_create = 0 ; 
	 
    if(data.create_easyecom_account1) { 	
		if(data.easyeocm_username!='' && data.easyeocm_password!='' && data.easyeocm_api_key!='' && data.easyeocm_location_key!='') {
			
			easy_create = 1 ; 
			formData.append("easycreate", easy_create ) ; 
			 
			if(data.email != data.easyeocm_username)
			formData.append("eusername",data.easyeocm_username) ;
			else 
			formData.append("eusername",data.email) ;	

			if(data.password != data.easyeocm_password)
			formData.append("epassword",data.easyeocm_password) ;
			else 
			formData.append("epassword",data.password) ;	
			
			if(data.easyeocm_api_key!= '' )
			formData.append("eapikey",data.easyeocm_api_key) ;
			
			if(data.easyeocm_location_key != '')
			formData.append("elocation",data.easyeocm_location_key) ;
		} 
	}  
	
	if( easy_create == 0 &&  data.create_easyecom_account2 ) {
		if(data.easyeocm_agg_clientid!='' && data.easyeocm_ship_address!='' && data.easyeocm_bill_address!='' && data.easyeocm_state!='' && data.easyeocm_pin_code!='' && data.easyeocm_comp_address1!=''  && data.easyeocm_comp_address2!='' && data.easyeocm_country!='' ) {	

			easy_create = 2 ; 
			formData.append("easycreate", easy_create ) ;
			 
			formData.append("eshipadd1",data.easyeocm_ship_address1) ;
			formData.append("eshipadd2",data.easyeocm_ship_address2) ;
			formData.append("eshipstate",data.easyeocm_ship_address_state) ;
			formData.append("eship_pin",data.easyeocm_ship_address_pincode) ;
			// formData.append("eshipcountry",data.easyeocm_ship_address_country) ;
			
			formData.append("ebilladd1",data.easyeocm_bill_address1) ;
			formData.append("ebilladd2",data.easyeocm_bill_address2) ;
			formData.append("ebillstate",data.easyeocm_bill_address_state) ;
			formData.append("ebill_pin",data.easyeocm_bill_address_pincode) ;
			formData.append("easyeocm_amazon_token",data.easyeocm_amazon_token) ;
	 
		}
	} 
	
 
	if(data.create_easyecom_courier) { 	
  		
			easy_create = 3 ; 
			/* if(data.company_name)
			formData.append("easycourier_username", data.company_name ) ; 
			 
			if(data.password)
			formData.append("easycourier_password",data.password) ;
			 */
			 
			formData.append("easycourier_create", 1 ) ;  
			 
			if(data.easyecom_registered_email != '')
			formData.append("easyecom_email", data.easyecom_registered_email) ;
			 
			if(data.easyecom_registered_password != '')
			formData.append("easyecom_password", data.easyecom_registered_password) ;
			
			if(data.easyecom_registered_locationkey != '')  
			formData.append("easyecom_locationkey",data.easyecom_registered_locationkey) ;
	}   
	
	 
		 
     axios
      .post(process.env.REACT_APP_ADD_MERCHANT, formData)
      .then((res) => {
        if(res.data.error === false){
            props.setAddModal(false);
            props.tableDataFetch();
			 Swal.fire({
            title: 'Success',
            text: res.data.msg,
            icon: 'success',
          }) 
        }
      })
       .catch((err) => Swal.fire({title: 'Error', text: err, icon: 'error',})); 
  };
  
  const onError = (errors, e) => console.log('Error', errors);
  
  return (
        <Form onSubmit={MerchantSubmit(onAddMerchantSubmit,onError)}>
        <Row className="mx-0 justify-content-center addModal">
            <Col sm={6}>
                <FormGroup>
                <Label for="companyName_add">Enter Company Name</Label>
                <input {...addMerchant("company_name",{ required: 'Please Enter Company Name'})} className="form-control-sm form-control" placeholder="Enter Company Name*" id="companyName_add" />
                {errors.company_name && <span className="text-danger d-block mb-2">{errors.company_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="email_add">Enter Email</Label>
                <input {...addMerchant("email",{ required: 'Please Enter Email'})} type="email" className="form-control-sm form-control" placeholder="Enter Email*"  id="email_add"/>
                {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="brandName_add">Enter Store Name</Label>
                <input {...addMerchant("brand_name",{ required: 'Please Stores Name'})} className="form-control-sm form-control" placeholder="Enter Store Name*" id="brandName_add" />
                    {errors.brand_name && <span className="text-danger d-block mb-2">{errors.brand_name.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="contact_add">Enter Phone Number</Label>
                <input {...addMerchant("mobile",{ required: 'Please Enter Phone Number'})} type="number" className="form-control-sm form-control" placeholder="Enter Phone Number*" id="contact_add" />
                    {errors.mobile && <span className="text-danger d-block mb-2">{errors.mobile.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="walletBalance_add">Enter Wallet Balance</Label>
                <input {...addMerchant("wallet_balance",{ required: 'Please Wallet Balance'})} type="number" className="form-control-sm form-control" placeholder="Enter Wallet Balance*" id="walletBalance_add" />
                    {errors.wallet_balance && <span className="text-danger d-block mb-2">{errors.wallet_balance.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="maxLiabShip_add">Enter Max Liability Shipment</Label>
                <input {...addMerchant("max_liability_per_shipment",{ required: 'Please Enter Max Liability Shipment'})} type="number" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiabShip_add" />
                    {errors.max_liability_per_shipment && <span className="text-danger d-block mb-2">{errors.max_liability_per_shipment.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
                    <input {...addMerchant("remittance_tat_period",{ required: 'Please Enter Remittance TAT Period'})} className="form-control-sm form-control" placeholder="Enter Remittance TAT Period*" defaultValue="5" id="remittance_tat_period_add" />
                    {errors.remittance_tat_period && <span className="text-danger d-block error">{errors.remittance_tat_period.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="refer_by_add">Refer By</Label>
                <input {...addMerchant("refer_by")} className="form-control-sm form-control" placeholder="Enter Refer By" id="refer_by_add" />
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
                    <input {...addMerchant("max_cod_value_per_order",{pattern: {value:/^[+-]?\d+(\.\d+)?$/,message:'Please Enter Valid Max COD Value'}})} type="text" className="form-control-sm form-control" placeholder="Enter Max COD Value*" id="maxCodVal_add" />
                    {errors.max_cod_value_per_order && <span className="text-danger d-block error">{errors.max_cod_value_per_order.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="password_add">Enter Password</Label>
                <input {...addMerchant("password", { required: 'Please Enter Password'})} type="password" className="form-control-sm form-control" placeholder="Enter Password*" id="password_add" />
                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="confirmpass_add">Enter Confirm Password</Label>
                <input {...addMerchant("confirmPassword", { required: 'Please Enter Confirm', validate: value => value === password.current || "The passwords do not match"})} type="password" className="form-control-sm form-control" placeholder="Enter Confirm Password*" id="confirmpass_add" />
                    {errors.confirmPassword && <span className="text-danger d-block mb-2">{errors.confirmPassword.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                <Label for="uploadLogo_add">Upload Stores logo</Label>
                <input {...addMerchant("brandlogo", { required: 'Please Add Logo'})} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                    {errors.brandlogo && <span className="text-danger d-block mb-2">{errors.brandlogo.message}</span>}
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                <Label>Billing</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="2" id="preBill_add" defaultChecked />
                        Prepaid Billing
                        </Label> 
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="1" id="postBill_add" />
                        Postpaid Billing
                        </Label>
                    </span>
                    </div>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                    <Label>Freight Type</Label>
                    <div>
                    <span className="mr-2">
                        <Label check>
                        <input className="mx-2"  {...addMerchant("freight_type", { required: true })} type="radio" value="1" id="fixCharge_add" defaultChecked />
                        Fixed Charge
                        </Label>
                    </span>
                    <span>
                        <Label check>
                        <input className="mx-2" {...addMerchant("freight_type", { required: true })} type="radio" value="2" id="varCharge_add" />
                        Variables Charge
                        </Label>
                    </span>
                    </div>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup check className="pl-0">
                <Label>Status</Label>
                <div>
                    <span className="mr-2">
                    <Label check>
                        <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                        Active
                    </Label>
                    </span>
                    <span>
                    <Label check>
                        <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="0" id="inactive_add" />
                        InActive
                    </Label>
                    </span>
                </div>
                </FormGroup>
            </Col>
	       <Col sm={6}></Col>
        </Row>
		
		<Row> <Col sm={6}> <br/> </Col> <Col sm={6}> <br/></Col> </Row>
		 
		<Row> <Col sm={6}> <br/> </Col> <Col sm={6}> <br/></Col> </Row>
		
		<Row>
			 
			
			<Col sm={6}>
			 <FormGroup>
				<Label check>
				<input className="mx-2" {...addMerchant("create_easyecom_courier")} type="checkbox" value={checked3} id="create_easyecom_courier" checked={checked3} onChange={handleOptionChange3} onclick={checkother3} />
				<b>Add EasyEcom Courier</b>
				</Label>
			</FormGroup>
            </Col>
			
			<Col sm={6}>
			 <FormGroup>
				<Label check>
				<input className="mx-2" {...addMerchant("create_easyecom_account2")} type="checkbox" value={checked2}id="create_easyecom_account2" checked={checked2} onChange={handleOptionChange2}   onClick={checkother2}  />
				<b>Add EasyEcom Customer</b>
				</Label>
			</FormGroup>
            </Col>
	 
		</Row>	
			
		<Row>	
			 
			 	
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_ship_address1">Shipping Address (of Company) Line1</Label>
				<input {...addMerchant("easyeocm_ship_address1", { required: 'Please Enter Shiping Address'})}  className="form-control-sm form-control" placeholder="Enter Shipping Address of compnay (line1) *" id="easyeocm_ship_address1" />
				{errors.easyeocm_ship_address1 && <span className="text-danger d-block mb-2">{errors.easyeocm_ship_address1.message}</span>}
				</FormGroup>
				</Col>
			}
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_ship_address2">Shipping Address (of Company) Line2</Label>
				<input {...addMerchant("easyeocm_ship_address2")}  className="form-control-sm form-control" placeholder="Enter Shipping Address of compnay (line2)" id="easyeocm_ship_address2" />
				{errors.easyeocm_ship_address2 && <span className="text-danger d-block mb-2">{errors.easyeocm_ship_address2.message}</span>}
				</FormGroup>
				</Col>
			}
			
			{ ecomform2==1  && 
				<Col sm={6}>
					<FormGroup> 
					<Label for="easyeocm_ship_address_state">Shipping Address State</Label>
					<select {...addMerchant("easyeocm_ship_address_state", { required: 'Please Select Shipping State'})} className="form-control-sm form-control">
					<option value="">Select a Shipping State</option>
					{Object.entries(easyecom_statecodes).map(([key, value]) => (
					<option value={value}>{key}</option>
					))}
					</select>
					{errors.easyeocm_ship_address_state && <span className="text-danger d-block mb-2">{errors.easyeocm_ship_address_state.message}</span>}
					</FormGroup>
				</Col>
			}
			
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_ship_address_pincode">Pincode (of Shipping Address )</Label>
				<input {...addMerchant("easyeocm_ship_address_pincode" , { required: 'Please Enter Shipping Address Pincode'} )}  className="form-control-sm form-control" placeholder="Enter Shipping Address Pincode*" id="easyeocm_ship_address_pincode" />
				{errors.easyeocm_ship_address_pincode && <span className="text-danger d-block mb-2">{errors.easyeocm_ship_address_pincode.message}</span>}
				</FormGroup>
				</Col>
			}
			
			
			{ ecomform2==4  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_ship_address_country">Country (of Shipping Address )</Label>
				<input {...addMerchant("easyeocm_ship_address_country" )}  className="form-control-sm form-control" placeholder="Enter Shipping Address Country*" id="easyeocm_ship_address_country" />
				{errors.easyeocm_ship_address_country && <span className="text-danger d-block mb-2">{errors.easyeocm_ship_address_country.message}</span>}
				</FormGroup>
				</Col>
			}
			 
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_bill_address1">Billing Address (of Company) Line 1</Label>
				<input {...addMerchant("easyeocm_bill_address1" ,{ required: 'Please Enter Billing Address (line1)'})}  className="form-control-sm form-control" placeholder="Enter Billing Address of merchant line1 *" id="easyeocm_bill_address1" />
				</FormGroup>
				</Col>
			}
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_bill_address2">Billing Address (of Company) Line2</Label>
				<input {...addMerchant("easyeocm_bill_address2", { required: 'Please Enter Billing Address (line2)'})}  className="form-control-sm form-control" placeholder="Enter Billing Address of compnay (line2)" id="easyeocm_bill_address2" />
				{errors.easyeocm_bill_address2 && <span className="text-danger d-block mb-2">{errors.easyeocm_bill_address2.message}</span>}
				</FormGroup>
				</Col>
			}
			
			{ ecomform2==1  && 
				<Col sm={6}>
					<FormGroup> 
					<Label for="easyeocm_bill_address_state">Billing Address State</Label>
					<select {...addMerchant("easyeocm_bill_address_state", { required: 'Please Select Billing State'})} className="form-control-sm form-control">
					<option value="">Select a State</option>
					{Object.entries(easyecom_statecodes).map(([key, value]) => (
					<option value={value}>{key}</option>
					))}
					</select>
					{errors.easyeocm_bill_address_state && <span className="text-danger d-block mb-2">{errors.easyeocm_bill_address_state.message}</span>}
					</FormGroup>
				</Col>
			}
			
			
			
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_bill_address_pincode">Pincode (of Billing Address )</Label>
				<input {...addMerchant("easyeocm_bill_address_pincode" , { required: 'Please Enter Billing Address Pincode'} )}  className="form-control-sm form-control" placeholder="Enter Billing Address Pincode*" id="easyeocm_bill_address_pincode" />
				{errors.easyeocm_bill_address_pincode && <span className="text-danger d-block mb-2">{errors.easyeocm_bill_address_pincode.message}</span>}
				</FormGroup>
				</Col>
			}
			
			{ ecomform2==1  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_amazon_token">Amazon Token</Label>
				<input {...addMerchant("easyeocm_amazon_token" , { required: 'Please Enter Amazon Token'} )}  className="form-control-sm form-control" placeholder="Enter Amazon Token*" id="easyeocm_amazon_token" />
				{errors.easyeocm_amazon_token && <span className="text-danger d-block mb-2">{errors.easyeocm_amazon_token.message}</span>}
				</FormGroup>
				</Col>
			}
			
			
			
			{ ecomform2==4  && 
				<Col sm={6}>
				<FormGroup>
				<Label for="easyeocm_bill_address_country">Country (of Billing Address )</Label>
				<input {...addMerchant("easyeocm_bill_address_country" )}  className="form-control-sm form-control" placeholder="Enter Billing Address Country*" id="easyeocm_bill_address_country" />
				{errors.easyeocm_bill_address_country && <span className="text-danger d-block mb-2">{errors.easyeocm_bill_address_country.message}</span>}
				</FormGroup>
				</Col>
			}
			 
 
             
			{ checked3==1 &&
			  
			  <Col sm={6}>
				<FormGroup>
				<Label for="easyecom_registered_email">EasyEcom Email</Label>
				<input {...addMerchant("easyecom_registered_email")}  className="form-control-sm form-control" placeholder="Enter EasyEcom Registered Email*" id="easyecom_registered_email" />
				{errors.easyecom_registered_email && <span className="text-danger d-block mb-2">{errors.easyecom_registered_email.message}</span>}
				</FormGroup>
				</Col>
				
			}	
		    
			{ checked3==1 &&
			  <Col sm={6}>
				<FormGroup>
				<Label for="easyecom_registered_password">EasyEcom Password</Label>
				<input {...addMerchant("easyecom_registered_password" )}  className="form-control-sm form-control" placeholder="Enter EasyEcom Password*" id="easyecom_registered_password" />
				{errors.easyecom_registered_password && <span className="text-danger d-block mb-2">{errors.easyecom_registered_password.message}</span>}
				</FormGroup>
				</Col>
				
			}
			
			{ checked3==1 &&
			  
			  <Col sm={6}>
				<FormGroup>
				<Label for="easyecom_registered_locationkey">EasyEcom LocationKey</Label>
				<input {...addMerchant("easyecom_registered_locationkey"  )}  className="form-control-sm form-control" placeholder="Enter EasyEom LocationKey*" id="easyecom_registered_locationkey" />
				{errors.easyecom_registered_locationkey && <span className="text-danger d-block mb-2">{errors.easyecom_registered_locationkey.message}</span>}
				</FormGroup>
				</Col>
				
			}
			
			 
		</Row>
		
		 
		
		<Row>
		 <Col sm={12} className="text-right">
            <Button className="btn btn-sm" color="primary">Save Merchant</Button>
            </Col>
		</Row>
        
		<Row> <Col sm={6}> <br/> </Col> <Col sm={6}> <br/></Col> </Row>
		
		</Form>  
                
  );
}

export default AddMerchant;