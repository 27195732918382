import React,{useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardTitle, FormGroup,Form, CardHeader, Table, Button, Label, Container, Spinner} from 'reactstrap';
import { useForm } from "react-hook-form";
import {useHistory, useParams} from 'react-router-dom';
// import axios from 'axios';
import axios from '../../CommonAxios';
import Swal from 'sweetalert2'
import { checkData } from '../../ObjectExist';
import { ArrowLeft } from 'react-feather';
import { states } from '../../config';


const EditPickupPoint = (props) => {
    const [loader, setLoader] = useState(false);
    const [viewPickup, setViewPickup] = useState({})
    const { register:editPickupPoint, handleSubmit:pickupPointSubmit, formState: { errors }, reset:reset2 } = useForm();
    const {merchantId} = useParams();

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    useEffect(() =>{
        setLoader(true);
        axios({
          method: "post",
          url: `${process.env.REACT_APP_MERCHANT_PICKUPPOINT_VIEW}/${merchantId}`,
        })
          .then(function (response) {
            setLoader(false);
            let myResponse=response.data[0];
            reset2(myResponse);
            setViewPickup(myResponse)
          }).catch((err) => {
            setLoader(false);
            Swal.fire({title: 'Error', text: err, icon: 'error',});
            });
      },[merchantId, reset2])
    const onEditPickupPoint = (data) => {
        setLoader(true);
        let formData = new FormData();
      formData.append("contact_name", data.contact_name);
      formData.append("pickup_details_for_label", data.pickup_details_for_label);
      formData.append("return_details_for_label", data.return_details_for_label);
      formData.append("address_label_bottom_line", data.address_label_bottom_line);
      formData.append("smart_ship_hub_id", data.smart_ship_hub_id);
      formData.append("smart_ship_1kg_hub_id", data.smart_ship_1kg_hub_id);
      formData.append("smart_ship_bulk_hub_id", data.smart_ship_bulk_hub_id);
      formData.append("smart_ship_exp_hub_id", data.smart_ship_exp_hub_id);
	  
	  formData.append("warehouse_name", data.warehouse_name);
	  formData.append("contact_email", data.contact_email);
  
      axios
        .post(`${process.env.REACT_APP_MERCHANT_PICKUPPOINT_UPDATE}/${merchantId}`, formData)
        .then((res) => {
            setLoader(false);
            if(res.data.error === false){
            Swal.fire({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
            })
          }
        }).catch((err) => {
            setLoader(false);
            Swal.fire({title: 'Error', text: err, icon: 'error',});
        });
    };
    const onError = (errors, e) => console.log('Error', errors);

    return (
        <div className="content-body overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
            {Object.entries(viewPickup).length > 0 &&
            <Container fluid>
          <Row>
           <Col sm={12}>
            <Card className="p-0 py-3 mt-3 addPickupPoints shadow">
              <Card className="mb-3 border-0">
                <CardHeader className="d-flex justify-content-between border-bottom">
                  <CardTitle className="mb-0">VIEW WAREHOUSES</CardTitle>
                        <div className="card-header-right d-flex align-items-center ml-auto">
                        <div onClick={goToPreviousPath} className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back</span></div>
                        </div>
                </CardHeader>
                <CardBody className="forms">
                    <Row>
                        <Col sm={6}>
                        <CardTitle className="mb-0">Pickup Details</CardTitle>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th>Pickup Address 1 :</th>
                                        <td>{viewPickup.pickup_address_1}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Address 2 :</th>
                                        <td>{viewPickup.pickup_address_2}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Pincode :</th>
                                        <td>{viewPickup.pickup_pincode}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup City :</th>
                                        <td>{viewPickup.pickup_city_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup State :</th>
                                        <td>{states[viewPickup.pickup_state_id]}</td>
                                    </tr>
                                    <tr>
                                        <th>Pickup Mobile :</th>
                                        <td>{viewPickup.pickup_phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        
                        <Col sm={6}>
                        <CardTitle className="mb-0">Return Details</CardTitle>
                            <Table>
                                <tbody>
                                    <tr>
                                        <th className="border-top-0">Return Address 1 :</th>
                                        <td>{viewPickup.return_address_1}</td>
                                    </tr>
                                    <tr>
                                        <th>Return Address 2 :</th>
                                        <td>{viewPickup.return_address_2}</td>
                                    </tr>
                                    <tr>
                                        <th>Return Pincode :</th>
                                        <td>{viewPickup.return_pincode}</td>
                                    </tr>
                                    <tr>
                                        <th>Return City :</th>
                                        <td>{viewPickup.return_city_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Return State :</th>
                                        <td>State Name</td>
                                    </tr>
                                    <tr>
                                        <th>Return Mobile :</th>
                                        <td>{viewPickup.return_phone}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        
                    </Row>
                    <Form onSubmit={pickupPointSubmit(onEditPickupPoint,onError)}>
                        <Card>
                            <CardHeader><CardTitle>Edit Info</CardTitle></CardHeader>
                            <CardBody className="pt-0">
                                <Row className="mx-0">
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>Contact Name</Label>
                                            <input {...editPickupPoint("contact_name",{ required: 'Please Enter Contact Name'})}  className="form-control-sm form-control" placeholder="Full Name" />
                                            {errors.contact_name && <span className="text-danger d-block mb-2">{errors.contact_name.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
									
									<Col sm={12}>
                                        <FormGroup>
                                            <Label>Warehouse Name</Label>
                                            <input {...editPickupPoint("warehouse_name",{ required: 'Please Enter Warehouse Name'})}  className="form-control-sm form-control" placeholder="Warehouse Name" />
                                            {errors.rapid_warehouse_name && <span className="text-danger d-block mb-2">{errors.rapid_warehouse_name.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
									
									<Col sm={12}>
                                        <FormGroup>
                                            <Label>Contact Email</Label>
                                            <input {...editPickupPoint("contact_email",{ required: 'Please Enter Warehouse Contact Email', email: 'Please enter valid email'})}  className="form-control-sm form-control" placeholder="Warehouse Contact Email" type="email"  />
                                            {errors.contact_email && <span className="text-danger d-block mb-2">{errors.contact_email.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
									
                                    {checkData(props.user) && (props.user[0].user_type === "Super" || props.user[0].user_type === "SubAdmin") &&
                                    <>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Smart Ship Hub Id</Label>
                                            <input {...editPickupPoint("smart_ship_hub_id")}  className="form-control-sm form-control" placeholder="Smart Ship Hub Id" />
                                            {errors.smart_ship_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_hub_id.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Smart Ship 1Kg Hub Id</Label>
                                            <input {...editPickupPoint("smart_ship_1kg_hub_id")}  className="form-control-sm form-control" placeholder="Smart Ship 1Kg Hub Id" />
                                            {errors.smart_ship_1kg_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_1kg_hub_id.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Smart Ship Bulk Hub Id</Label>
                                            <input {...editPickupPoint("smart_ship_bulk_hub_id")}  className="form-control-sm form-control" placeholder="Smart Ship Bulk Hub Id" />
                                            {errors.smart_ship_bulk_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_bulk_hub_id.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>Smart Ship Express Hub Id</Label>
                                            <input {...editPickupPoint("smart_ship_exp_hub_id")}  className="form-control-sm form-control" placeholder="Smart Ship Express Hub Id" />
                                            {errors.smart_ship_exp_hub_id && <span className="text-danger d-block mb-2">{errors.smart_ship_exp_hub_id.message}</span>}
                                        </FormGroup>                                                     
                                    </Col>
                                    </>
                                }
                                </Row>
                            </CardBody>
                        </Card>
                      <Row className="mx-0">
                        <Col md={6}>
                            <Card>
                                <CardHeader className="d-flex justify-content-between"><CardTitle>Pickup Details</CardTitle><div><small>(Send to Courier)</small></div></CardHeader>
                                <CardBody className="pt-0">
                                    <FormGroup>
                                        <textarea {...editPickupPoint("pickup_details_for_label")}  className="form-control-sm form-control" type="textarea" placeholder="Pickup Details For address label" />
                                    </FormGroup>
                                </CardBody>
                            </Card>                                               
                        </Col>
                        <Col md={6}>
                            <Card>
                                <CardHeader className="d-flex justify-content-between"><CardTitle>Return Details</CardTitle>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    
                                    <FormGroup>
                                        <textarea {...editPickupPoint("return_details_for_label")}  className="form-control-sm form-control" placeholder="Pickup Details For address label" />
                                    </FormGroup>
                                </CardBody>
                            </Card>                                               
                        </Col>
                        
                      </Row>
                      <Row className="mx-0 d-none">
                        <Col sm={12}>
                            <Card>
                                <CardHeader><CardTitle className="mb-0">Address Labels Bottom Line</CardTitle><small>(e.g note)</small></CardHeader>
                                <CardBody className="pt-0">
                                    <FormGroup>
                                    <textarea {...editPickupPoint("address_label_bottom_line")} className="form-control-sm form-control" placeholder="Address Lables Bottom Line" />
                                    </FormGroup> 
                                </CardBody>
                            </Card>                                               
                        </Col>
                      </Row>
                      <Row>
                      <Col sm={12} className="text-right">
                        <Button className="btn  btn-sm" color="primary">Save</Button>
                      </Col>
                      </Row>
                    </Form>
                </CardBody>                    
              </Card>
            </Card>
          </Col>
          
          </Row>
          </Container>
          }
           </div>
    );
}

export default EditPickupPoint;