import React, {useState, useEffect} from 'react';
import { Row, Col, Label, Form, FormGroup, Button, CardTitle, Card, CardBody, CardImg, UncontrolledCollapse, CustomInput, Table, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2'
import config from '../../../config';
// import PricingTable from '../../pricing/PricingTable'; 
import { checkData, checkUndeNullBlank } from '../../../ObjectExist';
 
 
const StandardPricing = (props) => {
	const [loader, setLoader] = useState(false) ;
	const [stdpricing, setPricing] = useState(true) ; 
 
    const [mpricing, setMpricing] = useState('') ;
   
      useEffect(() => {

       }, [])
    return (
      <>
      {loader && <div className="formLoader"><Spinner /></div>}
         
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">Pricings </CardTitle>
            </div>
            <Row>
			{ stdpricing && 
			    
				<div className="show_pricing">
				  
				  <table className="merchant_pricing" width="100%">
				   <tr> <td colSpan={12}  className="tbl_header_text"><p> Standard Services Excluding GST </p> </td>  </tr>
				  
				  <tr className="header_row">
				  <th>Carrier</th> <th>Movement</th> <th>Forward</th><th>WIthin<br/>City</th><th>Within<br/>State</th>
				  <th>Within<br/>Zone</th><th>Metro to <br/> Metro</th> <th>ROI</th> <th>Extended<br/> Location</th>  
				  <th>Special Zone</th>  <th>COD</th>  <th>%</th> 
				  </tr>
				  
					<tbody>	
					
						<tr> <td rowspan="2" className="courier_name">Blue dart</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gms</td> <td>34</td> <td>39</td> <td>42</td> <td>49</td> <td>50</td> <td>56</td> <td>73</td><td rowspan="2">40</td><td rowspan="2">2.00%</td></tr>
						<tr><td className="pricetitle">Excess 500gms</td> <td>34</td> <td>35</td> <td>36</td> <td>42</td> <td>43</td> <td>46</td> <td>48</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2" className="courier_name">Blue dart</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 500gms</td> <td>29</td> <td>31</td> <td>32</td> <td>41</td> <td>42</td> <td>49</td> <td>59</td><td rowspan="2" className="emptyper">&nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>   <td className="pricetitle">Excess 500gms</td> <td>29</td> <td>31</td> <td>34</td> <td>35</td> <td>36</td> <td>39</td> <td>42</td> </tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gms</td> <td>37</td> <td>42</td> <td>46</td> <td>49</td> <td>52</td> <td>54</td> <td>61</td><td rowspan="2">40</td><td rowspan="2">2.00%</td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>12</td> <td>16</td> <td>18</td> <td>26</td> <td>30</td> <td>32</td> <td>37</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 500gms</td> <td>31</td> <td>36</td> <td>36</td> <td>38</td> <td>41</td> <td>43</td> <td>50</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>31</td> <td>36</td> <td>36</td> <td>38</td> <td>41</td> <td>43</td> <td>50</td> </tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 5 Kg</td> <td>137</td> <td>172</td> <td>219</td> <td>240</td> <td>254</td> <td>309</td> <td>344</td><td rowspan="2">60</td><td rowspan="2">3</td></tr>
						<tr>   <td className="pricetitle">Excess 1 Kg</td> <td>26</td> <td>29</td> <td>35</td> <td>38</td> <td>43</td> <td>52</td> <td>62</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 5 Kg</td> <td>137</td> <td>172</td> <td>219</td> <td>240</td> <td>254</td> <td>309</td> <td>344</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>   <td className="pricetitle">Excess 1 Kg</td> <td>26</td> <td>29</td> <td>35</td> <td>38</td> <td>43</td> <td>52</td> <td>62</td> </tr>
					 	
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 10 Kg</td> <td>184</td> <td>230</td> <td>294</td> <td>322</td> <td>341</td> <td>413</td> <td>459</td><td rowspan="2">60</td><td rowspan="2">3</td></tr>
						<tr>   <td className="pricetitle">Excess 1 Kg</td> <td>16</td> <td>20</td> <td>22</td> <td>25</td> <td>29</td> <td>39</td> <td>41</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 10Kg</td> <td>184</td> <td>230</td> <td>294</td> <td>322</td> <td>341</td> <td>413</td><td>459</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>   <td className="pricetitle">Excess 1Kg</td> <td>16</td> <td>20</td> <td>22</td> <td>25</td> <td>19</td> <td>39</td> <td>41</td> </tr> 
 
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						<tr> <td rowspan="2"  className="courier_name">Xpressbees</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gm</td> <td>31</td> <td>35</td> <td>38</td> <td>43</td> <td>47</td> <td>51</td> <td>59</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>   <td className="pricetitle">Additional 500gm</td> <td>23</td> <td>26</td> <td>26</td> <td>27</td> <td>28</td> <td>31</td> <td>34</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Xpressbees</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">2 Kg</td> <td>76</td> <td>89</td> <td>95</td> <td>103</td> <td>116</td> <td>122</td> <td>135</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>   <td className="pricetitle">Additional 1Kg</td> <td>20</td> <td>23</td> <td>27</td> <td>28</td> <td>34</td> <td>36</td> <td>38</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Xpressbees</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">5Kg</td> <td>142</td> <td>149</td> <td>162</td> <td>182</td> <td>196</td> <td>209</td> <td>236</td><td rowspan="2"> 40</td><td rowspan="2">2</td></tr>
						<tr>  <td className="pricetitle">Additional 1Kg</td> <td>22</td> <td>24</td> <td>26</td> <td>29</td> <td>31</td> <td>33</td> <td>37</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Xpressbees</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">10 Kg</td> <td>243</td> <td>284</td> <td>311</td> <td>338</td> <td>365</td> <td>392</td> <td>405</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>  <td className="pricetitle">Additional 1Kg</td> <td>20</td> <td>22</td> <td>24</td> <td>26</td> <td>30</td> <td>31</td> <td>34</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Ecom Express</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gms</td> <td>30</td> <td>34</td> <td>38</td> <td>45</td> <td>58</td> <td>78</td> <td>153</td><td rowspan="2">40</td><td rowspan="2">2.5</td></tr>
						<tr> <td className="pricetitle">Excess 500gms</td> <td>27</td> <td>32</td> <td>34</td> <td>42</td> <td>56</td> <td>74</td> <td>149</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Ekart</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gms</td> <td>39</td> <td>42</td> <td>45</td> <td>50</td> <td>53</td> <td>57</td> <td>69</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>12</td> <td>18</td> <td>22</td> <td>28</td> <td>35</td> <td>37</td> <td>45</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Ekart</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 500gms</td> <td>30</td> <td>32</td> <td>35</td> <td>38</td> <td>38</td> <td>43</td> <td>51</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>11</td> <td>16</td> <td>18</td> <td>25</td> <td>33</td> <td>35</td> <td>43</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Dtdc Surface</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 5Kg</td> <td>95</td> <td>108</td> <td>122</td> <td>155</td> <td>196</td> <td>230</td> <td>270</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 1 Kg</td> <td>19</td> <td>22</td> <td>25</td> <td>31</td> <td>39</td> <td>46</td> <td>54</td></tr>
						
						<tr className="lastemptytblrow"><td colspan="12"> </td></tr>
						
						</tbody>
						</table>
						
						<br/> <br/>
						
						
						<table className="merchant_pricing" width="100%">
						<tr> <td colSpan={12} className="tbl_header_text"><p>Express Services Excluding GST</p> </td>  </tr>
						<tr className="header_row">
						<th>Carrier</th> <th>Movement</th> <th>Forward</th><th>WIthin<br/>City</th><th>Within<br/>State</th>
						<th>Within<br/>Zone</th><th>Metro to <br/> Metro</th> <th>ROI</th> <th>Extended<br/> Location</th>  
						<th>Special Zone</th>  <th>COD</th>  <th>%</th> 
						</tr>

						<tbody>

						<tr> <td rowspan="2"  className="courier_name">Blue Dart</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gm</td> <td>35</td> <td>57</td> <td>61</td> <td>63</td> <td>88</td> <td>95</td> <td>105</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>35</td> <td>41</td> <td>50</td> <td>61</td> <td>74</td> <td>81</td> <td>90</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Blue Dart</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 500gm</td> <td>27</td> <td>41</td> <td>47</td> <td>53</td> <td>74</td> <td>81</td> <td>88</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>27</td> <td>35</td> <td>39</td> <td>47</td> <td>61</td> <td>68</td> <td>73</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gm</td> <td>42</td> <td>47</td> <td>54</td> <td>63</td> <td>74</td> <td>81</td> <td>100</td><td rowspan="2">40</td><td rowspan="2">2</td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>11</td> <td>18</td> <td>27</td> <td>46</td> <td>51</td> <td>54</td> <td>65</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">Delhivery</td> <td rowspan="2" className="movement">RTO</td> <td className="pricetitle">First 500gm</td> <td>35</td> <td>41</td> <td>46</td> <td>51</td> <td>57</td> <td>62</td> <td>70</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>35</td> <td>41</td> <td>46</td> <td>51</td> <td>57</td> <td>62</td> <td>70</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr><td rowspan="2"  className="courier_name">DTDC Priority</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gm</td> <td>31</td> <td>39</td> <td>47</td> <td>62</td> <td>70</td> <td>74</td> <td>78</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>   <td className="pricetitle">Excess 500gms</td> <td>19</td> <td>31</td> <td>39</td> <td>54</td> <td>62</td> <td>65</td> <td>70</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">DTDC Priority</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 5KG</td> <td>155</td> <td>236</td> <td>311</td> <td>506</td> <td>581</td> <td>608</td> <td>662</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>   <td className="pricetitle">Excess 1Kg</td> <td>31</td> <td>47</td> <td>62</td> <td>101</td> <td>116</td> <td>122</td> <td>132</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						<tr> <td rowspan="2"  className="courier_name">DTDC Premium</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 500gms</td> <td>54</td> <td>62</td> <td>78</td> <td>124</td> <td>140</td> <td>154</td> <td>194</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 500gms</td> <td>54</td> <td>62</td> <td>78</td> <td>124</td> <td>140</td> <td>154</td> <td>194</td></tr>
						
						<tr className="emptytblrow"><td colspan="12"> </td></tr>
						
						
						<tr> <td rowspan="2"  className="courier_name">DTDC Premium</td> <td rowspan="2" className="movement">Forward</td> <td className="pricetitle">First 5Kg</td> <td>351</td> <td>425</td> <td>506</td> <td>891</td> <td>972</td> <td>1026</td> <td>1165</td><td rowspan="2" className="emptyper"> &nbsp; </td><td rowspan="2" className="emptyper"> &nbsp; </td></tr>
						<tr>  <td className="pricetitle">Excess 1Kg</td> <td>70</td> <td>85</td> <td>101</td> <td>179</td> <td>194</td> <td>205</td> <td>233</td></tr>
					 </tbody>
					 <tr className="tblinfo"> <td colspan="12"> <p> GST would be charged Extra. <br/> </p></td> </tr>
					</table>
				
				 
			      <div className="pricing_info">
				  <p>
					If you ship more than 500 shipments in a month then you will get 5% discount on billing.<br/>
					If you ship more than 1000 shipments in a month then you will get 7% discount on billing.<br/>
					If you ship more than 2000 shipments in a month then you will get 10% discount on billing.<br/>
					If you ship more than 3000 shipments in a month then contact to our team for suitable pricing.<br/>
				  </p>		
				  </div>
				  <br/> <br/> <br/>
				</div>
			}
           </Row>
        </>
    );
}

export default StandardPricing ;