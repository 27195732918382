import React, {useState, useEffect} from 'react';
import { Row, Col, Form,CardTitle, Card, CardBody, CardImg, UncontrolledCollapse, CustomInput, Table, Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
import axios from '../../../CommonAxios';
import Swal from 'sweetalert2'
import config from '../../../config';
import PricingTable from '../../pricing/PricingTable'; 

const MyCouriers = (props) => {
  const [courierPricing, setCourierPricing] = useState([]);
  const [courierList, setCourierList] = useState({});
  const [dispZonePrice, setdispZonePrice] = useState(false); 
 const [loader, setLoader] = useState(false)
 const [tableloader, setTableLoader] = useState(false)
 const getCourierStatus = (courier_id, e )=> {
  setLoader(true)
  axios({
    method: "post",
    url : process.env.REACT_APP_MERCHANT_COURIER_STATUS,
    data: {"merchant_id" : props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:'', "status_id" : e.target.checked ? e.target.value : '0', "courier_id" : courier_id}
  }).then(function (response) {
    if(response.data.error === false){
    setLoader(false)
      Swal.fire({
        title: 'Success',
        text: response.data.message,
        icon: 'success',
      })
    }
      couurierListing()
  }).catch((err) => {
    Swal.fire({icon:'error',text:err,title:'Error'});
  });

};
    const couurierListing = () => {
        setTableLoader(true)
        axios({
          method: "post",
          url : process.env.REACT_APP_MERCHANT_COURIER_LIST,
          data: {"merchant_id" : props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:''}
        }).then(function (response) {
          setTableLoader(false);
          if(response.data.error===false){
            setCourierList(response.data.data)
            courierPriceing();
          }else{
            Swal.fire({ title: 'Error',text: response.data.msg?response.data.msg:'Something went wrong',icon: 'error'});
          }
          
        }).catch((err) => {
          setTableLoader(false);
          Swal.fire({icon:'error',text:err,title:'Error'});
        });
      }
      const courierPriceing = () => {
        axios({
          method: "post",
          url : process.env.REACT_APP_MERCHANT_COURIER_PRICING,
          data: {"merchant_id" : props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:''}
        }).then(function (response) {
          if(response.data.fatal !== true){
          setCourierPricing(response.data)
          }
        }).catch((err) => {
          Swal.fire({icon:'error',text:err,title:'Error'});
        });
      }
      useEffect(() => {
        couurierListing();
          // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [])
    return (
      <>
      {loader && <div className="formLoader"><Spinner /></div>}
        <Form>
            <div className="sub-page-header">
                <CardTitle tag="h4" className="sub-page-title">My Couriers</CardTitle>
            </div>
            <Row>
            {courierList.courierList !== null && courierList.courierList !== undefined && Object.entries(courierList.courierList).length >0 ? courierList.courierList.map((item, index) => (
              <>
              <Card className="m-1">
                  <CardBody className="d-flex justify-content-between align-items-center">
                  <div id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faChevronDown} /></div>
                  <div id={`couriertoggler${item.courier_id}`}>Courier ID - <span className="font-weight-500">{item.courier_id}</span></div>
                  <div className="courierLogo" id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}> <CardImg top src={`${process.env.REACT_APP_BASE_URL+'/'+courierList.path+'/'+item.logo}`} alt="logo" style={{maxWidth:'60px'}} /></div>
                  <div className="courierName" id={`couriertoggler${item.courier_id}`} style={{cursor:'pointer'}}>{item.brand_name}</div>
                  <div class="courierSwitch">
                    <CustomInput type="switch" id={`switchToggle${item.courier_id}`} onClick={(e) => getCourierStatus(item.courier_id, e)}  value="1" className="switch-md" label={config.status[item.status]} defaultChecked={item.status===1}/>                    
                  </div>
                  </CardBody>
              </Card>
              <UncontrolledCollapse toggler={`couriertoggler${item.courier_id}`}>
               <CardBody className={`px-0 ${item.courier_id}`}>
                 {courierPricing !== undefined && courierPricing !== null && Object.entries(courierPricing).length >0 ?
                 <>
               {/* <PricingTable movement_type_id={item.movement_type_id} pricingList={courierPricing[item.courier_id !== undefined && item.courier_id]} courierId={item.courier_id} merchantId={props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant" ?props.user[0].id:''} priceApiUrl={process.env.REACT_APP_MERCHANT_PRICING_ADD} disabled={true} /> */}
               <Table responsive className="text-center price-view">
                  <thead>
                  <tr>
                      <th>Courier Name</th>
                      <th>Direction</th>
                      <th className="text-nowrap">Weight Slab <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>Within City <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>Within State <br /> <span className='basci-add'>Basic | Additional</span></th>
					  
					  { dispZonePrice &&
						<th>Within Zone <br /> <span className='basci-add'>Basic | Additional</span></th>
					  }
                      <th>M to M <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>ROI <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>Ext. Location <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>Special  Dest. <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>COD <br /> <span className='basci-add'>Basic | Additional</span></th>
                      <th>COD % <br /> <span className='basci-add'>Basic | Additional</span></th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="2" className="align-middle font-weight-bold">{item.brand_name}</td>
                      <td>Forword</td>
                      <td>{courierPricing[item.courier_id].frwd_b.weight_in_kgs} | {courierPricing[item.courier_id].frwd_a.weight_in_kgs}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.within_city} | {courierPricing[item.courier_id].frwd_a.within_city}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.within_state} | {courierPricing[item.courier_id].frwd_a.within_state}</td>
				{ dispZonePrice &&
				<td>{courierPricing[item.courier_id].frwd_b.within_zone} | {courierPricing[item.courier_id].frwd_a.within_zone}</td> 
				}
                      <td>{courierPricing[item.courier_id].frwd_b.metro} | {courierPricing[item.courier_id].frwd_a.metro}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.roi} | {courierPricing[item.courier_id].frwd_a.roi}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.extended_location} | {courierPricing[item.courier_id].frwd_a.extended_location}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.special_destination} | {courierPricing[item.courier_id].frwd_a.special_destination}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.cod_charge}</td>
                      <td>{courierPricing[item.courier_id].frwd_b.cod_percent}</td>
                    </tr>
                    {(item.movement_type_id === 1 || item.movement_type_id === '1') &&
                    <>
                    <tr>
                    <td>RTO</td>
                    <td>{courierPricing[item.courier_id].rto_b.weight_in_kgs} | {courierPricing[item.courier_id].rto_a.weight_in_kgs}</td>
                    <td>{courierPricing[item.courier_id].rto_b.within_city} | {courierPricing[item.courier_id].rto_a.within_city}</td>
                    <td>{courierPricing[item.courier_id].rto_b.within_state} | {courierPricing[item.courier_id].rto_a.within_state}</td>
			{  dispZonePrice && 
			<td>{courierPricing[item.courier_id].rto_b.within_zone} | {courierPricing[item.courier_id].rto_a.within_zone}</td>
			}
                    <td>{courierPricing[item.courier_id].rto_b.metro} | {courierPricing[item.courier_id].rto_a.metro}</td>
                    <td>{courierPricing[item.courier_id].rto_b.roi} | {courierPricing[item.courier_id].rto_a.roi}</td>
                    <td>{courierPricing[item.courier_id].rto_b.extended_location} | {courierPricing[item.courier_id].rto_a.extended_location}</td>
                    <td>{courierPricing[item.courier_id].rto_b.special_destination} | {courierPricing[item.courier_id].rto_a.special_destination}</td>
                    <td colSpan="2" className="align-bottom"></td>
                    </tr>
                    </>}                    
                    </tbody>
                  </Table>
               </>
               : tableloader? 'Loading...':'No Data Found'
              }
              </CardBody>
              </UncontrolledCollapse>
              </>
              )):<Col sm={12} className="text-center">No Data Found</Col>}          
            </Row>
        </Form>
        </>
    );
}

export default MyCouriers;