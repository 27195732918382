import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, Container, Col, Input, Table, FormGroup, Row, Form, Pagination, PaginationItem, PaginationLink, CardTitle, Spinner, } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom';
import { changeIntoDate } from '../../utlis';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faAngleDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Status from '../Status';
import AddMerchant from './AddMerchant';
import EditMerchant from './EditMerchant';
import { checkData, checkPermission, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';
const configData = require("../../config");

const Merchant = (props) => {
  const [editMerchant, setEditMerchant] = useState({});
  const [merchant, setMerchant] = useState({});
  const [totalPages, setTotalPages] = useState();
  const [addmodal, setAddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const dropFilterCount = 20

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
  }
  const toggleAdd = () => setAddModal(!addmodal);
  console.log('totalPages', totalPages)

  const toggleEditclose = () => {
    seteditModal(!editmodal);
    setEditMerchant({});
  }
  const toggleEdit = (i) => {
    setEditMerchant({});
    seteditModal(!editmodal)
    fetch(`${process.env.REACT_APP_GET_MERCHANT}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        'x-ref': window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        let myResponse = result[0];
        if (myResponse !== undefined) {
          if (myResponse.billing !== undefined) {
            myResponse.billing = String(myResponse.billing);
          }
          if (myResponse.freight_type !== undefined) {
            myResponse.freight_type = String(myResponse.freight_type);
          }
          if (myResponse.status !== undefined) {
            myResponse.status = String(myResponse.status);
          }
          if (myResponse.ship_status !== undefined) {
            myResponse.ship_status = String(myResponse.ship_status);
          }
          if (myResponse.document_status !== undefined) {
            myResponse.document_status = String(myResponse.document_status);
          }
        }
        setEditMerchant(result)
      });
    });
  };
  const tableDataFetch = () => {
    setLoadertable(true);
    setFilterInput('');
    setCurrentCount(1)
    setPage(1)
    fetch(`${process.env.REACT_APP_GET_MERCHANT}?page=${page}&per_page=${selectperpage}&admin_id=${checkData(props.user[0]) ? props.user[0].id : ''}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoadertable(false);
        if (result.fatal !== true) {
          setMerchant(result)
          setTotalPages(result.totalPage)
        }
      });
    }).catch((err) => {
      setLoadertable(false);
      Swal.fire({ title: 'Error', text: err, icon: 'error' })
    });
  }
  useEffect(() => {
    tableDataFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage]);
  function nextPageData() {
    if (checkData(merchant.data) && checkUndeNullBlank(merchant.totalPage)) {
      // &&checkUndeNullBlank(merchant.totalPage).length>page.length
      if (merchant.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchant.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchant.data)
      if (currentCount > Object.entries(merchant.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register, handleSubmit } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)

  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name) || checkUndeNullBlank(data.email)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_MERCHANT}?brand_name=${checkUndeNullBlank(data.brand_name) ? data.brand_name : ''}&email=${checkUndeNullBlank(data.email) ? data.email : ''}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}&admin_id=${checkUndeNullBlank(props.user[0].id) ? props.user[0].id : ''}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        'x-ref': window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setMerchant(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      Swal.fire({ icon: "error", title: "Error", text: err })
    });
  };
  return (
    <div className="content-body">
      <div className="background-strip"></div>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Container fluid>
        <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
          <div className="mb-3 mr-3">
            <h4 className="text-white font-w600 mb-0">Merchants</h4>
          </div>
          <div className="modal-btn">
            {checkPermission(props.user, props?.user[0]?.permissions?.manage_Merchants?.add) &&
              <Button color="light" onClick={toggleAdd}>Add Merchant</Button>
            }
          </div>
          <Modal isOpen={addmodal} toggle={addmodal} size="lg" scrollable="true">
            <ModalHeader toggle={toggleAdd}>Add Merchant</ModalHeader>
            <ModalBody>
              <AddMerchant setAddModal={setAddModal} tableDataFetch={tableDataFetch} user={props.user} />
            </ModalBody>
          </Modal>
        </div>
        <div className="master-content card border-0">
          <Modal isOpen={editmodal} toggle={editmodal} size="lg" scrollable="true">
            <ModalHeader toggle={toggleEditclose}>Edit Merchant</ModalHeader>
            <ModalBody>
              {editMerchant !== undefined && editMerchant !== null && Object.entries(editMerchant).length > 0 &&
                <EditMerchant editMerchant={editMerchant} seteditModal={seteditModal} tableDataFetch={tableDataFetch} user={props.user} />
              }
            </ModalBody>
          </Modal>
          <Row className="justify-content-between card-header align-items-center px-3 pt-3">
            <Col sm={3}><CardTitle>Manage Merchants</CardTitle></Col>
          </Row>
          <div className="filter-form">
            <Form onSubmit={handleSubmit(onSubmit)} className="filter-form">
              <Row className="mx-0 pt-1 justify-content-start justify-content-md-center">
                <Col sm={6} md={4} lg={3} xl={3}>
                  <FormGroup>
                    <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Enter Name" />
                  </FormGroup>

                </Col>
                <Col sm={6} md={4} lg={3} xl={3}>
                  <FormGroup>
                    <input {...register("email")} type="email" className="form-control-sm form-control" placeholder="Enter Email" />
                  </FormGroup>
                </Col>
                <Col sm={3} className="filter-btn">
                  <Button className="btn  btn-sm" color="primary">Search</Button>
                  <Button type="reset" className="btn  btn-sm mx-2" color="primary" outline onClick={tableDataFetch}>Reset</Button>
                </Col>
              </Row>
            </Form>
          </div>
          {checkData(merchant) && checkData(merchant.data) && checkUndeNullBlank(merchant.TotalRows) ?
            <>
              <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                <div className="showing-count">
                  Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchant.data).length)} of {merchant.TotalRows} entries
                </div>
                {merchant.TotalRows > dropFilterCount ?
                  <Form>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <Input type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm">
                        <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Input>
                    </FormGroup>
                  </Form>
                  : ''}
              </div>
            </>
            : ''}
          <Table responsive borderless className="text-center customTable manage_merchants">
            <thead>
              <tr>
                <th>ID</th>
                <th>Register Name</th>
                <th>Merchant Email</th>
                <th>Registered Date</th>
                <th>Trading Date</th>
                <th className="text-nowrap">Account Type</th>
                <th>Limit/Balance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {checkData(merchant.data) ? merchant.data.map((item, index) => (
                <tr>
                  <td>{item.id}</td>
                  <td>{item.brand_name}</td>
                  <td>{item.email}</td>
                  <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                  <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                  <td>{configData.account_type[item.billing]}</td>
                  <td>{item.wallet_balance}</td>
                  <td className="text-nowrap text-right"><span className="mr-2"><Status statusUrl={process.env.REACT_APP_GET_MERCHANT} listId={item.id} valStatus={item.status} reListing={tableDataFetch} data={{ "merchant_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>

                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_Merchants?.edit) &&
                      <span><Button className="btn btn-sm ml-2" color="info" outline onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                    }

                    <span><UncontrolledDropdown className="d-inline-block">
                      <DropdownToggle className="btn btn-sm ml-2" outline color="secondary" >
                        More<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link to={`/merchants-details/${item.id}`} className="dropdown-item">Merchant Details</Link>
                        {checkPermission(props.user, props?.user[0]?.permissions?.merchant_Warehouses?.list) &&
                          <Link to={`/merchants-pickuppoint/${item.id}`} className="dropdown-item">Warehouses</Link>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.merchant_Brands?.list) &&
                          <Link to={`/merchants-brand/${item.id}`} className="dropdown-item">Merchant Brands</Link>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.merchant_Pricing?.merchant_pricing) &&
                          <Link to={`/merchant-pricing/${item.id}?merchantName=${item.brand_name}`} className="dropdown-item">Merchant Pricing</Link>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.merchant_Passbook?.merchant_passbook) &&
                          <Link to={`/merchant-passbook/${item.id}`} className="dropdown-item">Merchant Passbook</Link>
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    </span>
                  </td>
                </tr>
              ))
                : loadertable ? <tr><td colSpan="8">Loading...</td></tr> :
                  <tr><td colSpan="8">No Data Found</td></tr>}
            </tbody>
          </Table>
          {checkData(merchant) && checkData(merchant.data) && checkUndeNullBlank(merchant.TotalRows) ?
            <>
              {merchant.TotalRows > dropFilterCount ?
                <div className="pagination-table d-flex justify-content-between flex-wrap align-items-center">
                  <div className="showing-count">
                    Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchant.data).length)} of {merchant.TotalRows} entries
                  </div>
                  <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                      <PaginationLink previous href="#" onClick={previousPageData}><FontAwesomeIcon icon={faChevronLeft} /></PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink next href="#" onClick={nextPageData}><FontAwesomeIcon icon={faChevronRight} /></PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
                : ''}
            </>
            : ''}
        </div>
      </Container>
    </div>
  )
}
export default Merchant