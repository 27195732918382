import React,{useState} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import {ChevronLeft} from 'react-feather';
import loginBg from "../../assets/images/loginbg.png";
// import Logo from '../assets/img/logo.png';
import { useForm } from 'react-hook-form';


const ForgotPassword = (props) => {
    const [otpTxt] = useState(true);
    const [paswrdTxt] = useState(true);
    const { register:forgotinput, handleSubmit:ForgotPasswordSubmit, formState: { errors }} = useForm();
    const onMerchantForgotPassword = (data) => {
        if(!otpTxt && paswrdTxt){
            //OTP validate  api
            // dispatch(MerchantOtpPassword(data)).then(data => {
            //     console.log('otpVerify', data)
            //     setPaswrdTxt(data.error)
            //   })

        }else if(!paswrdTxt){
            // pasword change api
            // dispatch(ResetMerchantPassword(data)).then(data => {
            //     console.log('password set', data);
            //     if(data.error !== true){
            //     Swal.fire({icon:'success',text:data.msg,title:'Success'});
            //     }
            //   })
        }else{
            //email validate API
            // dispatch(PasswordForgot(data)).then(data => {
            //     console.log('otprerecieve', data)
            //     setOtpTxtt(data.error)
            //   })
        }
        
        
    };
      const onError = (errors, e) => console.log('Error', errors);

    return (
        <div className="app-content m-0 p-0 content overflow-hidden">
		<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NX6FQZMK" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Col sm={12} lg={8} className="d-none d-lg-flex align-items-center auth-left p-0">
                        <div className="d-lg-flex justify-content-center align-items-center w-100">
                            <img className="img-fluid" src={loginBg} alt="img" />
                        </div>
                    </Col>
                    <Col sm={12} lg={4} className="d-flex align-items-center shadow p-3 bg-white">
                        <Card className="border-0 px-xl-3 mx-auto">
                            <CardHeader className="my-3">
                            <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3">Logo</CardTitle></Link>
                            </CardHeader>
                              <CardTitle tag="h4">Forgot Password? 🔒</CardTitle>
                            <CardBody className="align-items-center py-3">
                              <p className="card-text">Enter your email and we'll send you instructions to reset your password.</p>
                              <Form onSubmit={ForgotPasswordSubmit(onMerchantForgotPassword,onError)}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <input {...forgotinput("email",{ required: 'Please Enter Email'})} className="form-control" disabled={!otpTxt && !paswrdTxt} placeholder="Enter Email" id="email" />
                                    {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                                </FormGroup>
                                {!otpTxt && paswrdTxt && <FormGroup>
                                    <Label for="otp">OTP</Label>
                                    <input {...forgotinput("otp",{ required: 'Please Enter OTP'})} className="form-control" placeholder="Enter OTP" id="otp" />
                                    {errors.otp && <span className="text-danger d-block mb-2">{errors.otp.message}</span>}
                                </FormGroup>}
                                {!otpTxt && !paswrdTxt && <FormGroup>
                                    <Label for="password">New Password</Label>
                                    <input {...forgotinput("password",{ required: 'Please Enter New Password'})} type="password" className="form-control" placeholder="Enter New Password" id="password" />
                                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                </FormGroup>}
                                <FormGroup>              
                                    <Button className="btn-sm w-100" color="primary"> Send reset link</Button>                                     
                                </FormGroup>
                                <p className="text-center">                                    
                                    <Link to="/my-signin"><ChevronLeft /><span>Back to login</span></Link>
                                </p>
                              </Form>
                            </CardBody>
                        </Card>                          
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
export default ForgotPassword