import React, { useState, useEffect } from "react";
import { Row, Container, TabContent, TabPane, Nav, NavItem, NavLink, Col } from 'reactstrap';
import classnames from 'classnames';
import MerchantRemittance from "./MerchantRemittance";
import InitiatedRemittance from "./InitiatedRemittance";
import RemittanceTransactions from "./RemittanceTransactions";
import RemittanceAdjusted from "./RemittanceAdjusted";
import CourierRemittance from "./CourierRemittance";
import { checkPermission } from "../../ObjectExist";
// import RemainingRemmitance from "./RemainingRemmitance" ;

const Remittance = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    //   const toggleDataAdd = () => setDataAdd(!dataAdd);
    useEffect(() => {
    }, []);

    return (
        <div className="content-body position-relative overflow-hidden content-remittance">
           <div className="container-fluid">
                <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between position-relative">
                    <div className="page-header main-header"> 
					<h1> Manage Remittance </h1>
				
                    <Nav tabs className="master-tabs">
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <span class="text-white">Merchant Remittance</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_initiated) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    <span class="text-white">Initate Remittance</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_transactions) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '3' })}
                                    onClick={() => { toggle('3'); }}
                                >
                                    <span class="text-white">Remittance Transactions</span>
                                </NavLink>
                            </NavItem>
                        }
                        {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_adjusted_list) &&
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '4' })}
                                    onClick={() => { toggle('4'); }}
                                >
                                    <span class="text-white">Remittance Adjusted</span>
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '5' })}
                                onClick={() => { toggle('5'); }}
                            >
                                <span class="text-white">Courier Remittance</span>
                            </NavLink>
                        </NavItem>
						
						 
                    </Nav>
					</div>
                </div>
                <div className="master-content card border-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_list) &&
                                        <MerchantRemittance user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_initiated) &&
                                        <InitiatedRemittance user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_transactions) &&
                                        <RemittanceTransactions user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    {checkPermission(props.user, props?.user[0]?.permissions?.manage_COD?.remittance_adjusted_list) &&
                                        <RemittanceAdjusted user={props.user} />
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="5">
                            <Row>
                                <Col sm="12">
                                    <CourierRemittance user={props.user} />
                                </Col>
                            </Row>
                        </TabPane>
						
						 
						
                    </TabContent>
                </div>
            </div>
        </div>
    )
}
export default Remittance