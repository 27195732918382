import React,{useState,useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Spinner } from 'reactstrap';
import { Link,useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import Logo from "../../assets/images/logo.png";
// import AgreementPdf from '../../assets/agreement-for-website.pdf';
import { useForm } from 'react-hook-form';
import { SignupMerchant } from '../../actions';
import { connect, useDispatch } from "react-redux";
import Footer from '../Footer';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga';

const Signup = (props) => {
    const [passwordType, setPasswordType] = useState(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const history = useHistory();
  
  
	useEffect(() =>{
		 //alert('signp') ; 
	}, [])

    const { register:signupInput, handleSubmit:signupSubmit, formState: { errors }} = useForm();
	
	
    const onMerchantSignup = (data) => {
        data.admin_id = process.env.REACT_APP_ADMIN_ID
        setLoader(true);
		
		// console.log('current data is '+data.admin_id) ; 
	 	
        dispatch(SignupMerchant(data)).then(response => {
            setLoader(false)
            if(Object.entries(response).length >0 ){
                if(response.error === false){
                Swal.fire({icon:'success', text:response.msg, title:'Success',allowOutsideClick: false,allowEscapeKey: false})
                .then(okay => {
                    if (okay) {
                        history.push("/thanks");
                   }
                 });
                }else{
                    Swal.fire({icon:'error', text:response.msg, title:'Error'});
                }
            }else{
                Swal.fire({icon:'error', text:response.msg, title:'Error'});
            }
          }).catch(function (response) {
              console.log('resss', response)
            setLoader(false)
            Swal.fire({title:'Error', icon:'error', text:response})
          });
    };
      const onError = (errors, e) => Swal.fire({title:'Error', text:errors, icon: 'error'});
    return (


        <div className="app-content m-0 p-0 content overflow-hidden">
		<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NX6FQZMK" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
		<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11475314075"></script>
	<script id="gtag">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', 'AW-11475314075');
        `}
      </script>
		
        {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Col sm={12} lg={12} className="d-lg-flex align-items-center auth-left p-0 auth-bg position-relative">
                        <div className="d-lg-flex justify-content-center align-items-center w-100 credintials">
                            {/* <img className="img-fluid" src={loginBg} alt="img" /> */}
                            <Card className="border-0 px-xl-3 mx-auto col-md-8 col-lg-6">
                            <CardHeader className="my-3">
                            <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3"><img className="img-fluid logoimg" src={Logo} alt="img" /></CardTitle></Link>
                            </CardHeader>
                              <CardTitle tag="h4">Welcome !</CardTitle>
                            <CardBody className="align-items-center py-3">
                              <p className="card-text">Please Register To Your Account With Tekies Retail Private Limited.</p>
                              <Form onSubmit={signupSubmit(onMerchantSignup,onError)}>
                              <Row className='justify-content-center'>
                               <Col sm={6}>
                               <FormGroup>
                                    <Label for="brand_name">Stores NAME</Label>
                                    <input {...signupInput("brand_name",{ required: 'Please Enter Stores Name'})} className="form-control" placeholder="Please Enter Stores Name" id="brand_name" />
                                    {errors.brand_name && <span className="text-danger d-block mb-2">{errors.brand_name.message}</span>}
                                </FormGroup> 
                               </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="company_name">REGISTERED COMPANY NAME</Label>
                                    <input {...signupInput("company_name",{ required: 'Please Enter Company Name'})} className="form-control" placeholder="Please Enter Company Name" id="company_name" />
                                    {errors.company_name && <span className="text-danger d-block mb-2">{errors.company_name.message}</span>}
                                </FormGroup> 
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="company_type">COMPANY TYPE *</Label>
                                    <input {...signupInput("company_type",{ required: 'Please Enter Company Type'})} className="form-control" placeholder="Please Enter Company Type" id="company_type" />
                                    {errors.company_type && <span className="text-danger d-block mb-2">{errors.company_type.message}</span>}
                                </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <input {...signupInput("email",{ required: 'Please Enter Email'})} type="email" className="form-control" placeholder="Please Enter Email" id="email" />
                                    {errors.email && <span className="text-danger d-block mb-2">{errors.email.message}</span>}
                                </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="mobile">MOBILE</Label>
                                    <input {...signupInput("mobile",{ required: 'Please Enter Mobile No.'})} type="tel" className="form-control" placeholder="Please Enter Mobile No." id="mobile" />
                                    {errors.mobile && <span className="text-danger d-block mb-2">{errors.mobile.message}</span>}
                                </FormGroup> 
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <InputGroup>
                                        <input {...signupInput("password",{ required: 'Please Enter Password'})} type={passwordType? "password" : "text"} placeholder="Please Enter Password" className="form-control" id="password" />
                                        <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                            <InputGroupText className="py-0">{passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>  
                                    {errors.password && <span className="text-danger d-block mb-2">{errors.password.message}</span>}
                                </FormGroup>
                                </Col>
                                <Col sm={6}>
                                <FormGroup>
                                    <Label for="confirm_password">CONFIRM PASSWORD</Label>
                                    <InputGroup>
                                        <input {...signupInput("confirm_password",{ required: 'Please Enter Confirm Password'})} type={passwordType? "password" : "text"} placeholder="Please Enter Confirm Password" className="form-control" id="confirm_password" />
                                        <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                            <InputGroupText className="py-0">{passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>  
                                    {errors.confirm_password && <span className="text-danger d-block mb-2">{errors.confirm_password.message}</span>}
                                </FormGroup>
                                </Col>
                                <Col sm={12}>
                                <FormGroup className="text-center">
                                    <FormGroup check>
                                        <Label check><Input type="checkbox" hidden />By registering you agree to the Tackie <a href="/terms">Terms of Use</a> </Label>
                                    </FormGroup>
                                </FormGroup>
                                </Col>
                                <Col sm={6} className='m-auto'>
                                <FormGroup>              
                                    <Button className="btn-sm w-100" color="primary"> Sign Up</Button>                                     
                                </FormGroup>
                                </Col>
                                </Row>
                                <p className="text-center">
                                    <span>Already have an account ?</span>
                                    <Link to="/my-signin">Login</Link>
                                </p>
                              </Form>
                            </CardBody>
                        </Card> 
                        </div>
                        <div className="bg-overlay bg-primary"></div>
                        <ul className="bg-bubbles">
                            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                        </ul>
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
export default Signup