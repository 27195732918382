import React,{useState,useEffect} from 'react';
import { Row, Col, Card, CardBody,CardTitle,Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText, CardHeader, Spinner } from 'reactstrap';
import { Link,useHistory } from 'react-router-dom';
import {Eye, EyeOff } from 'react-feather';
import Logo from "../../assets/images/logo.png";
import { useForm } from 'react-hook-form';
 
import { connect, useDispatch } from "react-redux";
import Footer from '../Footer';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga';

const Terms = (props) => {
    //const [passwordType, setPasswordType] = useState(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const history = useHistory();
  
  
	useEffect(() =>{
	
	}, [])

    //const { register:signupInput, handleSubmit:signupSubmit, formState: { errors }} = useForm();
	
	
     
    const onError = (errors, e) => Swal.fire({title:'Error', text:errors, icon: 'error'});
	
	const insideOl = {
		"padding-top": "9pt",
		"padding-left": "14pt",
		"text-indent": "0pt",
		"line-height": "108%",
		"text-align": "left"
	}
	
	const card_title = {
		"padding-top": "9pt",
		"padding-left": "14pt",
		"line-height": "108%",
		"text-align": "left",
		"width": "100%",
		"text-align": "center",
		"font-size": "25px"
	}
	
    return (


        <div className="app-content Terms_Condition_Page m-0 p-0 content overflow-hidden">
		<noscript>
		<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NX6FQZMK" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
		<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11475314075"></script>
	<script id="gtag">
        {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
           gtag('config', 'AW-11475314075');
        `}
      </script>

        {loader && <div className="formLoader"><Spinner /></div>}
          <Row>
           <Col sm={12}>            
            <div className="p-0 LoginPage content-wrapper">
                <Row>
                    <Col sm={12} lg={12} className="d-lg-flex align-items-center auth-left p-0 auth-bg position-relative">
                        <div className="d-lg-flex justify-content-center align-items-center w-100 credintials">
                            
                            <Card className="border-0 px-xl-3 mx-auto col-md-10 col-lg-10">
                            <CardHeader className="my-3">
                            <Link href="#" className="brand-logo text-center" ><CardTitle tag="h3"><img className="img-fluid logoimg" src={Logo} alt="img" /></CardTitle></Link>
                            </CardHeader>
                             
                            <CardBody className="align-items-center py-3">
							<Row className='justify-content-center'>
									 
							<p className="s1">
							<strong><span className="time_new_roman">Terms and Conditions of Tekipost</span></strong>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Effective Date:</span></strong><span className="time_new_roman">&#xa0;</span><span className="time_new_roman">April 1, 2022</span><br /><strong><span className="time_new_roman">Last Updated:</span></strong><span className="time_new_roman">&#xa0;</span><span className="time_new_roman">January 10, 2023</span>
							</p>

							<p className="s2">
							<strong><span className="time_new_roman">General</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">These Terms and Conditions ("Terms") govern your use of the Tekipost platform, which includes the website, digital applications, and mobile apps ("Platform"). By using the Platform, you agree to comply with these Terms. If you disagree, please discontinue use.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">About Tekipost</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">Tekipost is a brand of Tekies Retail Private Limited, a company registered under the Companies Act, 2013, with its registered office at 807 Ashok Bhawan, 93 Nehru Place, New Delhi-110019, India.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Platform Services</span></strong>
							</p>
							<ol className="o_1">
							<li>
							<strong>Aggregator Role:</strong>&#xa0;Tekipost is a technology-based aggregator between users/merchants and courier partners. It facilitates automated shipping panel services integrated with various courier partners but does not directly handle delivery or shipment.
							</li>
							<li>
							<strong>Third-Party Content:</strong>&#xa0;Tekipost is not responsible for any content uploaded by third parties on the Platform and disclaims all liabilities arising from it.
							</li>
							<li>
							<strong>Service Disclaimer:</strong>&#xa0;The Platform is provided "as is" and "as available" without any warranties or conditions. Tekipost does not guarantee uninterrupted, timely, or error-free service.
							</li>
							<li>
							<strong>Linked Sites and Promotions:</strong>&#xa0;Tekipost may include links to third-party sites and run promotional campaigns. Tekipost is not responsible for the content or dealings with these third parties.
							</li>
							</ol>
							<p className="s2">
							<strong><span className="time_new_roman">Terms Subject to Change</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">Tekipost reserves the right to modify these Terms and any aspect of the Platform without prior notice. Continued use of the Platform constitutes acceptance of any changes.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Eligibility</span></strong>
							</p>
							<ol className="o_1"> 
							<li>
							<strong>Age Requirement:</strong>&#xa0;You must be 18 years or older and legally capable of entering into a binding agreement.
							</li>
							<li>
							<strong>Corporate Representation:</strong>&#xa0;If you represent a corporate entity, you confirm that you are authorized to bind the entity to these Terms.
							</li>
							</ol>
							<p className="s2">
							<strong><span className="time_new_roman">Intellectual Property</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">All content on the Platform, including text, graphics, software, and trademarks, is the intellectual property of Tekipost and its partners. Unauthorized reproduction or use of this content is prohibited.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Registration</span></strong>
							</p>
							<ol className="o_1">
							<li>
							<strong>Account Creation:</strong>&#xa0;You must create an account to access Tekipost services and provide accurate and complete information.
							</li>
							<li>
							<strong>Account Security:</strong>&#xa0;You are responsible for maintaining the confidentiality of your login credentials.
							</li>
							<li>
							<strong>Account Cancellation:</strong>&#xa0;You may cancel your account by contacting Tekipost, after which all your data will be permanently deleted.
							</li>
							</ol>
							<p className="s2">
							<strong><span className="time_new_roman">Payment</span></strong>
							</p>
							<ol className="o_1">
							<li>
							<strong>Payment Terms:</strong>&#xa0;Payments are due within five (5) days of invoice. Late payments will incur additional interest.
							</li>
							<li>
							<strong>Fees and Taxes:</strong>&#xa0;All fees are exclusive of applicable taxes.
							</li>
							<li>
							<strong>Invoice Disputes:</strong>&#xa0;Any disputes concerning invoices must be reported in writing within fifteen (15) days of the invoice date.
							</li>
							</ol>
							<p className="s2">
							<strong><span className="time_new_roman">Assignment of Rights</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">You may not transfer any rights or obligations under these Terms without Tekipost's written consent. Tekipost may transfer its rights without notice.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">User Discretion and Opt-Out</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">You may withdraw consent to these Terms by notifying Tekipost. Continued use of the Platform constitutes acceptance of the Terms.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Termination &amp; Cancellation</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">Tekipost may suspend or terminate your account for violations of these Terms or applicable laws. Upon termination, all data associated with your account will be deleted.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Refund Policy</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">All subscriptions are final, and no changes or refunds will be issued once payment is made.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Confidentiality</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">You agree not to disclose any confidential information related to Tekipost without prior written consent. Breaches of confidentiality will cause irreparable harm to Tekipost.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Warranties</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">Tekipost provides services "as is" and disclaims all implied warranties. The Platform does not guarantee uninterrupted or error-free services.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Governing Law &amp; Jurisdiction</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">All disputes will be resolved through arbitration in New Delhi, India, under the Arbitration and Conciliation Act of 1996. This agreement is governed by Indian law, with exclusive jurisdiction in New Delhi courts.</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Grievance Redressal</span></strong>
							</p>
							<p className="s2">
							<span className="time_new_roman">For grievances, contact the Grievance Officer:</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Grievance Officer:</span></strong><span className="time_new_roman">&#xa0;</span><span className="time_new_roman">Mr. Pawan Srivastava</span><br /><strong><span className="time_new_roman">Email:</span></strong><span className="time_new_roman">&#xa0;</span><span className="time_new_roman">pawan@tekipost.com</span><br /><strong><span className="time_new_roman">Address:</span></strong><span className="time_new_roman">&#xa0;</span><span className="time_new_roman">807 Ashok Bhawan, 93 Nehru Place, New Delhi-110019</span>
							</p>
							<p className="s2">
							<strong><span className="time_new_roman">Your use of the Platform confirms your agreement to these Terms and the Privacy Policy.</span></strong>
							</p>
							<p>
							&#xa0;
							</p>		
							
									<br/> <br/> <br/>
							 
							 </Row>
                         
                            </CardBody>
                        </Card> 
                        </div>
                        <div className="bg-overlay bg-primary"></div>
                        <ul className="bg-bubbles">
                            <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
                        </ul>
                    </Col>                    
                </Row>
              
            </div>
            </Col>         
          </Row>
        </div>
    );
}
export default Terms